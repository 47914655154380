.nav-top{
     position:fixed !important;
     width:100%;
}

.header-logo {
	float:left;
	width: 87px ;
	height: 103px;
}

.btn-donation2{
	color:#ffffff !important;
	background-color: #245798 !important;
	margin-top:-7px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	border-radius: 7px;
	height:40px;
	padding: 0px 30px !important;
}

.active-link{
	padding:0px 8px;
	font-weight: 900;
	color:rgba(0,0,0,1) !important;

	border-bottom:1px solid black; 
}

.active-about-class{
	font-weight: 900;
	font-color: black;
	margin-right:0px;
	padding-right:0px;
	border-bottom:1px solid black; 
	width:110px;
		margin-right:40px;
			color:rgba(0,0,0,1) !important;
}

.active-about-class a {
	color:rgba(0,0,0,1) !important;
}

.nav-css{
	background-color:black;
	height: 60px,
}

.sma-nav {
	width:100%;
}

.sma-nav a {
	height:30px;
	color: rgba(0,0,0,.5);
	margin-right:40px;
	font-size:16px;
	text-transform: uppercase;
	font-family:'Nunito';
}


.active-about {
	color:red;
}

.dropdown{
	margin-top:-7px;
}

.header-x{
	display:none;
}

.mobile-donate{
	display:none;
}

@media (max-width: 990px) {

	.nav-css{
		height:100%;
	}

		.header-logo{
		height:30px;
		width:24px;
	}

	.navbar-toggler-icon {
		margin-bottom:0px;
	}

	.sma-words{
		padding-top:20%;
	}
	.sma-nav{
		position:absolute;
		width: 105%;
    background-color: white;
    padding: 40px;
    padding-top:10%;
    margin-top: -13%;
    margin-left: -5%;
	}

	.header-x{
		 cursor:pointer;
		width:20px;
		float:right;
		display:block;
	}

	.mobile-donate{
		display:block;
		float:right;
	}

	.navbar {
		display: inline;
		grid-gap:10px;
		float:right;
	}


	.navbar button {
		margin-top:2px;
	}

	.navbar-toggler {
		width:55px;
		float:right;
		margin-left:10px;
	}
	span {
		margin-bottom:0px;
	}

	.lg-donate{
		display:none;
	}

	.sma-nav a {
		text-align:center;
		margin-bottom:6%;
		height:100%;
		margin-right:0px;
	}

	.active-link {
		padding:0px;
	}
	.dropdown {
		margin-top:-3%;
	    margin-bottom:-3%;
	    margin-left:3%;
	}

	.nav-item{
		width:100%;
	}

	.active-about-class{

	border-bottom:0px solid black; 
	}

  /* CSS that should be displayed if width is equal to or less than 800px goes here */
}