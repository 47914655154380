.mc-subcontainer {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	width:100%;
	padding:30px;
}

.mc-row {
	display:grid;
	grid-template-columns: 15% 20% 25% 15% 15%;
	grid-gap:15px;

}

.mc-container {
    margin:60px;
}

.mc-title {
	display:grid;
	grid-template-columns: 30% 37% 15% 15%;
	grid-gap:1%;
	margin:30px 0px;
}

.mc-button {
	display:grid;
	justify-content:right;
}

.mc-links-container{
	display:grid;
	grid-template-rows:20% 20%;
	grid-gap:10px;
}
.home-link {
	color:blue;
}


.edit-link{
	color: #53B7D5;
}

.delete-link {
	color: #EB5757;
}

.mc-tag{
	background: #FFFFFF;
/* Gray 5 */

border: 1px solid #E0E0E0;
box-sizing: border-box;
flex: none;
order: 0;
align-self: center;
flex-grow: 0;
margin: 10px 0px;
text-align:center;
width:80%;
  border-radius: 5px;
}
