.home-link {
	color:blue;
}

.donor-container {
	margin:60px;
}

.donor-ta {
	height:300px;
	width:100%;
}

.donor-subcontainer {
	display:grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap:50px;
}