.box-container {
	display:grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap:30px;
}

.box{
	border: 1px solid #E0E0E0;
	box-sizing: border-box;
	padding:20px;
}

.home-container {
	margin:100px;
}

