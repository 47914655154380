.d-container {

	width:600px;
	padding-top:10px;
	background-color:white;
	padding-left:30px;
	padding-right:30px;
	padding-bottom:50px;
	box-shadow: 0px 0px 10px rgba(131, 131, 131, 0.25);
}

.d-text{
	margin-top:30px;
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 160%;
	/* or 29px */


	color: #102034;

}

.d-info {
	margin-left:10px;
}

.d-small-text{
	margin-top:20px;
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 160%;
	/* or 29px */


	color: #102034;

}

.other-btn{
	margin-top:10px;
	border: 1px double #53B7D5;
	box-sizing: border-box;
	border-radius: 3px;
	height:50px;
	width:120px;
	padding:10px;
}


.d-four-columns {
	display:grid;
	grid-template-columns:1fr 1fr 1fr 1fr;
	grid-gap:25px;
}

.d-two-columns {
	display:grid;
	grid-template-columns:1fr 1fr;
	grid-gap:20px;
}

.d-receipt-row {
	display:grid;
	grid-template-columns:8% 1fr;
	justify-content:center;
	align-items:center;
	margin-top:30px;
}

.receipt-checkbox{
	margin-left:10px;

}





.d-receipt-text {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: 600;
	font-size:15px;
	margin-left:2%;
	line-height: 151%;
	/* or 23px */


	color: #000000;
}

.d-last-row{
	display:grid;
	grid-template-columns:1fr;
}

.btn-info{
	height:50px;
	font-size:14px;
}

.btn-outline-info{
	font-size:14px;
	height:50px;
}
.btn-donation{
	color:#ffffff !important;
	background-color: #245798 !important;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 7px;
	height:60px;
}

.donation-number-row{
	margin-top:20px;
	padding-left:5%;
	padding-right:5%;
	display:grid;
	grid-template-columns: 2% 1fr;
	grid-gap:30px;
	margin-bottom:20px;
}

.donation-number {
	color: #8D8D8D;
	font:24px;
}

.donation-or {
	margin-left:120px;
}

.pdf-icon {
	width:20px;
	margin-right:15px;
}

.d-icon {
	width:80px;
}
.donation-icon-row{
	margin-top:20px;
	padding-left:5%;
	padding-right:5%;
	display:grid;
	grid-template-columns: 10% 1fr;
	grid-gap:30px;
	margin-bottom:20px;
}

.paypal-thankyou{
	margin-top:30px;
	text-align:center;
}

.paypal-thanks-image {
	margin-bottom:30px;
	width:100px;
}

@media (max-width: 999px) { 

	.d-container {
		width:90%;
		margin-left:5%;
		margin-right:5%;
	}

	.d-four-columns{
		grid-gap:5px;
	}

	.d-four-columns Button{
		padding:0px;
	}

	.d-receipt-text{
			font-size:3vw;
	}

	.d-text {
		font-size:4vw;
	}

	.d-small-test {
		font-size:3vw;
	}

}