
.home-link {
	color:blue;
}

.uc-container {
	margin:60px;
}


.uc-image-button {
	width:30%;
}

.uc-cover-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	padding:30px;
}


.uc-image-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;

	width:60%;
	display:grid;
	padding:30px;
}
