.confirmation-container {

    position: fixed;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    width:300px;
    height:300px;
    border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display: grid;
	align-content:center;
	justify-content:center;
	background-color:white;
}

.checkmark {
	text-align:center;
	color:green;
	font-size:100px;
}

.sentence {
	text-align:center;
}