.involved-color {
		background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#53B7D5;
}

.involved {
	z-index:-1;
	background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#53B7D5;
	display:grid;
	text-align:center;
	height:500px;
	justify-content:center;
	align-content:center;
	grid-gap:10px;

}




.volunteer-words{
	z-index:1;
}

.involved-second h1 {
	text-align:left;
	color:black;
	font-size:60px;
}
.volunteer-dots-container {
	left:-40%;
	position:relative;
}

.volunteer-dots {
	position:absolute;
	margin-top:-20px;
	z-index:-2;
	height:230px;
}

.involved-words {
	font-weight:600;
	font-size:18px;
	color: #ffffff;
	padding:100px;

}

.involved-words > h1 {
	font-size:60px;
}

.involved-first-second{
	background-color:#53B7D5;
	margin-top:-100px;
	height:100%;
}

.involved-second{
		padding-top:10% 0%;
	z-index:1000;
		background:#F6CA45;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:center;
}


.volunteer-sub{
		display:grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas: 
      "text pic";
	align-content:center;
	justify-content:center;
		width: var(--main-width);
		text-align:left;
}

.volunteer-container {
	z-index:100;
	color:black;
	grid-area:text;
}

.black-arrow{
	width:30px;
}

.volunteer-container > p {

}

.volunteer-form-row {
	display:grid;
	grid-template-columns:25% 1fr;
	justify-content:center;
	align-content:center;
	margin: 30px 0px;
}

.volunteer-form-row a {
	color:black;
	font-weight:700;
}

.involved-second-pic {
	margin:10%;
	grid-area:pic;
}

.involved-mail, .involved-app{
	padding:0px 25%;
}

.involved-second-mobile-image{
	display:none;

}


.involved-fourth{
	display:grid;
	margin:80px 0px;
	justify-content:center;
	align-content:center;
}

.involved-fourth-sub{

	width: var(--main-width);
}

.involved-fourth h1 {
	color:black;
}

.involved-fourth-text {
	width:40%;
}

.charity-items-row {
	display:grid;
	grid-template-columns:1fr 1fr 1fr;
	grid-gap: 60px;
}

.charity-item {
	display:grid;
	justify-content:center;
	align-items:center;
}
.charity-item-pic{
	width:100%;

}

.charity-item-tag{
	background: #FFFFFF;
/* Gray 5 */
font-weight: 600;
height:40px;
color:#8D8D8D;
border: 2px solid #E0E0E0;
box-sizing: border-box;
flex: none;
order: 0;
align-self: center;
flex-grow: 0;
margin: 10px 0px;
text-align:center;
padding:0px 10px;
  border-radius: 5px;
  	display:grid;
	justify-content:center;
	align-items:center;
	width: fit-content;
}



.charity-item-tag-row{
	display:grid;
	grid-template-columns: 70% 1fr;
	justify-content:center;
	align-items:center;
}

.charity-item-title{
	margin-top:10px;
	height:80px;
}


.charity-item-container-mobile {
		position: absolute;
	left:40%;
 /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    margin-left:10%;
    margin-right:10%;
    padding:0% 5%;
    padding-bottom:0%;
	width: var(--main-width);
    height:750px;
    border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display: grid;
	grid-template-columns: 40% 1fr;
	grid-gap:5%;
	align-content:center;
	justify-content:center;
	background-color:white;
}

.charity-item-container-image{
	text-align:center;


}

.small-image-row {

	margin-top:10px;
	margin-left:30%;
	margin-right:30%;
	display:grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap:5%;

}

.charity-item-main-image {
	width:300px;
}

.charity-item-desc{
	margin-top:30px;
	height:360px;
	overflow-y: scroll;
}

.charity-item-text{
	height:600px;
}

.charity-interested-button{
	width:50px;
}

.charity-item-contact-first-row {
	display:grid;
	grid-template-columns: 1fr 1fr;
	grid-gap:5%;
}

.charity-item-contact-container {
padding:3%;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 10px;
}

.charity-item-contact-container input {
	margin-top:10px;
	width:100%;
	padding:1%;
	height:40px;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 5px;
}

.charity-item-contact-container p {
	margin-top:30px;
}

.btn-charity {

	background: #53B7D5;
border-radius: 3px;
padding:10px 40px;
color:white;
font-family: Noto Sans;
font-style: normal;
font-weight: bold;
font-size: 14px;
}

.btn-charity2 {

	background: #53B7D5;
border-radius: 3px;
padding:5px 50px;
color:white;
font-family: Noto Sans;
font-style: normal;
font-weight: bold;
font-size: 14px;
}
.charity-button-row {
	margin-top:5px;
	margin-bottom:15px;
	display:grid;
	justify-content:center;
	align-items:center;

}

.close-item-container{
	display:grid;
	justify-content:right;
}

.close-item {
	width:15px;
	text-align:right;
}

.email-row {
	margin-top:15px;
}

.detail-row{
	margin-top:15px;
}

.back-text{
	font-family: Nunito;
	font-style: normal;
	font-weight: 900;
	font-size: 14px;
	line-height: 151%;
	/* or 27px */

	letter-spacing: 0.1em;
	text-transform: uppercase;

	/* Middle grey */

	color: #C4C4C4;
}

.charity-back-arrow{
	width:8px;
	margin-right:15px;
}

.thank-you-text{
	margin-top:150px;
	font-weight: bold;
	font-size: 18px;
	text-align:center;
}


@media (max-width: 999px) { 
.charity-item-title{
	margin-top:0px;
	height:100%;
}

.small-image-row {
margin-bottom:3%;
}
	.involved {
		justify-content:inherit;
		height:100%;
		padding-top:30%;
		padding-bottom:30%;
	}


	.involved-words {
		padding:1%;
		width:100%;
	}
	.involved-words > h1 {
		font-size:8vw;
	}

	.involved-words p {
		font-size:3vw;
	}

	.involved-second-image{
		display:none;
	}
	.involved-second-mobile-image{
		display:block;
	}	

	.involved-second h1 {
		font-size:8vw;
	}

	.volunteer-container p {
		font-size:3vw;
	}

	.involved-second-pic{
		margin:0px;
		margin-bottom:5%;
		margin-top:5%;
	}
	.volunteer-sub{
		grid-template-columns: 1fr;
	}

	.involved-second{
		display:block;
		grid-template-areas: 
	      "pic"
	      "text"

	}

	.volunteer-sub{
		display:grid;
		grid-template-columns:1fr;
		grid-template-areas: 
	      "pic"
	      "text"
  }

  .involved-second{
  	padding-top:10%;
  	padding-left:3%;
  	padding-right:3%;
  	padding-bottom:10%;

  }

  .volunteer-form-row{
  	width:60%;
  }

  .involved-app{
  	padding:0px;
  	padding-right:40%;
  }

  .involved-mail{
  	padding:0px;
  	padding-right:38%;

  }

  .mail-row {
  	grid-template-columns: 15% 1fr;
  	width: 100%;
  }

  .involved-fourth {
  	padding:0px 3%;
  }

  .involved-fourth-text {
  	padding-bottom:60px;
  }

  .involved-fourth-title h1 {
  	font-size:8vw;
  }


  .involved-fourth-text{
  	width:100%;
  	font-size:4.5vw;
  }

  .charity-items-row {
  	grid-template-columns:1fr;
  }


.charity-item-container-mobile{
	display:block !important;
	align-content:flex-start;
  	position:absolute;
  	grid-template-columns:1fr;
  	margin-top:10%;
  	padding-top:40px;
  	height:1300px;
  	left:41%;
}
  .charity-item-desc{
  	height:200px;
  }

  .charity-item-text h4{
  	font-size:4vw;

  }

  .close-item {
  	width:25px;
  	height:25px;
  }

  .close-item-container{
  	padding-bottom:5%;
  }

  .charity-item-text div{
  	font-size:3.5vw;
  }

  .charity-item-contact-container div {
  	font-size:3vw;
  }

  .charity-item-contact-container input {
  	height:4vh;
  }

  .back-text {
  	font-size:3.5vw;
  }

  .charity-item-tag-row {
  	margin-top:3%;
  }

  .charity-item-tag {
  	height:6vh;
  }

  .charity-item-pic{



	}
}
@media (max-width: 450px){
	.charity-item-container-mobile{
		height:1000px;
	}
}