.footer {
	background: #102034;
	height:350px;
	display:grid;
	grid-template-columns:1fr 1fr 1fr 1fr;
	padding:60px;
	color:#ffffff;
}


.footer a {
	color:#ffffff;
}

.footer-icon {
	width:30px;
	margin-right:70px;
}

.footer-links {
	display:grid;
	align-content:flex-start;
	grid-template-columns:1fr;
	grid-gap:10px;
}

@media (max-width: 990px) {

	.footer{
		grid-template-columns:25% 30% 1fr;
		height: 100%;
		grid-gap:3px;
		padding-left:5%;
		padding-right:5%;
	}

	.footer a{
		font-size: 3vw;
	}
	.footer p{
		font-size: 3vw;
	}
	.footer-icon-rows{
		display:grid;
		grid-template-columns:1fr 1fr;
		margin-top:-70px;

	}

	
}