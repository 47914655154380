.home-link {
	color:blue;
}

.ma-container {
	margin:60px;
}

.ma-container button {
	float:left;
}
.ma-btn {
	width:400px;
	text-align:left;
}

.ma-box {
	background: #F2F2F2;
	padding:10px;
	margin:10px;
	display:grid;
	grid-template-columns:1fr 8% 8%;
	border: 1px solid #E0E0E0;
	box-sizing: border-box;
	width:400px;
	text-align:left;

}

.ma-box > a {
	color:black;
	font-weight:bold;
}

.ma-edit-link {
	text-align:right;
	color: #6FCF97;
}

.ma-x {
	text-align:right;
}