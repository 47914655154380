.landing-container{
    display:grid;
    grid-template-columns:1fr;

    margin:5% 25%;
    padding:5% 10%;
    border:1px solid grey;
}

.landing-subcontainer{
    display:grid;
    grid-template-columns: 30% 1fr;
    grid-gap:5%;
}

.landing-logo {
    width:146px;

}

.gopher{
 display: block;
 max-width: 100%;
 height: auto;
}

.landing-label{
    float:left;
    text-align:left;
}

.landing-input {
    height:30px;
}

.landing-button{
    border:0;
    margin-top:5%;
    margin-left:auto;
    margin-right:auto;
    width:40%;
    background:#2F80ED;
    color:white;
    height:60px;
    font-size:large;
}