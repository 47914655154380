.home-link {
	color:blue;
}

.special-container {
	margin:60px;
}

.special-ta {
	height:300px;
	width:100%;
}

.special-subcontainer {
	display:grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap:50px;
}

.special-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	align-content:center;
	padding:10%;
	justify-content:center;
}

img {
	max-width:100%;
	height: auto;
}

button {
	margin-top:10px;
}
