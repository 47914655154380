.mn-subcontainer {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	width:100%;
	padding:30px;
}

.mn-year-container{
	display:grid;
	grid-template-columns: 15% 1fr;
	align-items:center;
	margin-bottom:30px;

}
.mn-row {
	display:grid;
	grid-template-columns: 15% 20% 25% 10% 8% 8% 4%;
	grid-gap:15px;

}

.mn-container {
    margin:60px;
}

.home-link {
	color:blue;
}

.mn-content{
	width:100%;
	  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.edit-link{
	color: #53B7D5;
}

.delete-link {
	color: #EB5757;
}

.star-link {
	color:#F6CA45;
	font-size:16px;
}

.star-white-link {
	font-size:16px;
}