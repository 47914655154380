
.archive-container{

	background-color:#ffffff;

	margin-top:50px;
	display:grid;
	align-content:center;
	justify-content:center;
	margin-bottom:100px;
}

.archive-sub{
	display:grid;
	align-content:center;
	justify-content:center;
		width: var(--main-width);

}
.archive-color {
	background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#ffffff;
	height:100px;
}

.btn-categories{

background: #FFFFFF;
/* Middle grey */

border: 0.5px solid #C4C4C4;
box-sizing: border-box;
border-radius: 5px;
display:grid;
grid-template-columns:1fr 10%; 



align-items:center;

}



.btn-categories div{

}
.archive-first-container{
	display:grid;
	grid-template-columns: 1fr 1fr;
	grid-gap:30px;
}


.archive-first-date-container{
	margin-top:30px;
	display:grid;
	grid-template-columns: 80% 1fr;
	grid-gap:30px;
}

.archive-first  h1 {
	color:black;
	font-size:60px;
}

.archive-title-row {
	display:grid;
	grid-template-columns: 1fr 15%;
}

.archive-first-text {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 160%;
	color: #102034;
}

.archive-first-date {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 151%;
	color: black;
}

.archive-tbg{
	display:grid;
	grid-template-rows:1fr;
}

.archive-tb {
	background:white;
	background-color:white !important;
}

.archive-news-image {
	margin-bottom:10px;
	width:100%;
	display:grid;
	align-items:center;
	justify-content:center;
}

.btn-group-vertical{
	margin-left:20px;
	height:1000px;
	background:white;
}

.year-dropdown{
	display:none;
}

.archive-first-container iframe {
    border: 0px;
    height: 289px;
    width: 100%;
	}

@media (max-width: 999px) { 

	.btn-categories{

	display:inline-block;

	}
	.btn-categories div {
		float:left;
		margin-right:5%;
	}


	.archive-title-row{
		text-align:center;
	}

	.archive-title-row h1{
		font-size:10vw;
	}
	.archive-first-container {
		grid-template-columns:1fr;
		display:grid;
		justify-content:center;
	}

	.archive-first-date-container{
		grid-template-columns:1fr;
	}

	.btn-group-toggle{
		display:none;
	}

	.archive-first-container iframe {
		    margin-left: auto;
    margin-right: auto;
		padding-left:5%;
		padding-right:5%;
		border:0px;
				height:300px;
		width:400px;
	}


	.archive-first-container img{
		    margin-left: auto;
    margin-right: auto;
		padding-left:5%;
		padding-right:5%;
						height:300px;
		width:400px;
	}

	.archive-news-container {
		width:500px;
		display:grid;
		justify-content: center;
	}

	.archive-news-container a {
		display:grid;
	}

	.archive-first-text-container {
				padding-left:5%;
		padding-right:5%;
		width:400px;

	}

	.archive-title-row{
		grid-template-columns:1fr;
		display:grid;
		justify-content:center;
	}

	.archive-first-text{
		font-size:4vw;
				    margin-left: auto;
    margin-right: auto;

	}

	.archive-first-date{
				font-size:4vw;
	}

	.cat-dropdown {
		display:grid;
		justify-content:center;
	}
	.year-dropdown{
	display:block;
	margin-left:auto;
	margin-right:auto;
}	
	}

@media(max-width:450px){
	.archive-first-container iframe {
	padding-left:5%;
	padding-right:5%;
	height:225px;
	width:300px;
	border:0px;
}

.archive-first-container img{
	margin-left:auto;
	margin-right:auto;
	height:225px;
	width:300px;
}

.archive-first-text-container {
		margin-left:auto;
	margin-right:auto;
	width:300px;
}
}