
.home-link {
	color:blue;
}

.ql-editor h1,h2,h3{
	color:black;
}

.news-container {
	margin:60px;
}

.news-ta {
	height:300px;
	width:100%;
}

.an-links{
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.news-subcontainer {
	display:grid;
	grid-template-columns: 70% 1fr;
	grid-gap:50px;
}

.news-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;

	display:grid;
	padding:30px;
}

.cover-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:500px;
	display:grid;
	padding:30px;
}


.language-box {
	width:100%;

}

.header-ta {
	width:100%;
}

.content-ta {
	width:100%;
	height:400px;
}

.image-button {
	height:50px;
}

.image-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:200px;
	display:grid;
	padding:30px;
}
