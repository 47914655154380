::-webkit-input-placeholder {
   text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
   text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
   text-align: center;  
}

:-ms-input-placeholder {  
   text-align: center; 
}

.contact-container {

	width:100%;
	margin-top:100px;
	margin-bottom:100px;
	background-color:white;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:left;
}

.contact-sub{
	width: var(--main-width);
}

.contact-container-yellow {

	width:100%;
	padding-top:100px;
	padding-bottom:100px;
	background-color:#F6CA45;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:left;

}



.contact-container h1 {
	font-weight:900;
	font-family:Nunito;
	color:black;
}

.contact-container-yellow h1 {
font-weight: 900;
font-size: 60px;
	font-family:Nunito;
	color:black;
}

.contact-row {
	display:grid;
	grid-template-columns: 1fr 1fr;
}

.contact-subcontainer {
	width:100%;
	padding:3% 0%;

	padding-right:10%;

	border-right: 1px solid white;
}

.contact-first-row {
	display:grid;
	grid-template-columns:1fr 1fr;
	grid-gap:3%;
	margin-bottom:30px;
}
.contact-first-row input {
	width:100%;
	padding:1%;
	height:50px;
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	border-radius: 5px;
}


.contact-message {
	width:100%;
	height:140px;
		border-radius: 5px;
}

.contact-details{
	margin-left:10%;
	margin-right:10%;
	margin-top:100px;

}

.contact-thankyou {
	display:grid;
	align-content:center;
	justify-content:center; 
	text-align:center;
}

.contact-thankyou img{
		margin-left:40%;
	margin-right:40%;

}

.contact-button-container {
	margin-top:10px;
	text-align:left;
}

.contact-button {
	width:500px;
}

.thanks {
	width:100px;
}

.btn-contact2{
	color:#ffffff !important;
	background-color: #245798 !important;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	border-radius: 7px;
	width:200px;
	padding: 12px !important;
}

	.contact-map {
		margin-top:5%;
	}

	.contact-details-row {
		margin-bottom:17px;
	}

.message-redbox{
	background:#EB5757;
	font-color:white;
	color:white;
	padding:10px;
	margin-bottom:5px;
	width:60%;
}

@media (max-width: 999px) { 

	.contact-container-yellow h1{
		font-size:8vw;
	}

	.contact-container-yellow {
		justify-content:inherit;
		padding-left:5%;
		padding-right:5%;
	}

	.contact-container h1{
		font-size:8vw;
	}

	.contact-container {
		justify-content:inherit;
		padding-left:5%;
		padding-right:5%;
	}

	.contact-row {
		grid-template-columns:1fr;
	}

	.contact-row div {
		font-size:3vw;
	}

	.contact-subcontainer {
		padding-right:0%;
		border-right: 0px solid white;
				font-weight:600;
	}

	.contact-subcontainer input {
		height:5vh;
	}

	.btn-contact2 {
		width:100%;
	}

	.contact-first-row{
	}

	.contact-button {
		width:100%;
	}

	.contact-details {
		margin-top:30px;
	}

	.contact-details-row{
		margin-bottom:0px;
	}



}