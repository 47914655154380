
.stupid-scroll-container{
	margin-left:48%;
	margin-right:48%;
	position:relative;
	display:grid;
	align-items:center;
	justify-content:center;
}
.stupid-scroll{
	z-index:10000;
	position:absolute;
	margin-top:100px;
	color:white;
	display:grid;
	align-items:center;
	justify-content:center;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
}
.stupid-scroll-line{
		z-index:10000;
	position:absolute;
	margin-left:25px;
		margin-top:200px;
	height:50px;
	width:0px;
	border-right:3px solid white;
}
.sma-wave {
	position:relative;
}

.sma-wave-image{
	position:absolute;
	margin-top:100px;
	left:70%;
	width:600px;

}

.black-arrow {
	width:10px;
}
.sma-dots {
	position:relative;
	z-index:0;
}

.sma-dots-image{

	position:absolute;
	margin-top:-40px;
	left:-2%;
	width:300px;

}


.sma-color {
		background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#53B7D5;
		display:grid;
	align-content:center;
	justify-content:center;
}

.sma {
	background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#53B7D5;
	display:grid;
	height:500px;
	width:100%;
	text-align:center;

}

.sma-first-sub{
		width: var(--main-width);
		display:grid;
		align-items:center;
}

.item-image {
	margin-right:10px;
	width:30px;
}

.sma-words {

	text-align:left;
	font-weight:bold;
	font-size:20px;
	padding:100px 0px;
	color: #ffffff;
	width:100%;
}

.sma-words > h1 {
	padding-top:5%;
	font-size:80px;
}

.sma-first-second{
	background-color:#53B7D5;

	height:100%;
}


.sma-first-links {
	margin-top:50px;
	display:grid;
	grid-gap:30px;
	grid-template-columns:1fr 1fr;
}
.sma-second{
		background:#F6CA45;
	display:grid;
	grid-template-columns: 1fr;
	align-content:center;
	justify-content:center;
}



.sma-mail, .sma-app{
	padding:0px 25%;
}

.sma-second-container {
	margin-top:60px;
	display:grid;
	grid-template-columns: 20% 50% 20%;
	grid-gap:5%;
	grid-template-areas: 
      "leftLinks items references";
}

.sma-third-container{
	margin-bottom:10px;
	display:grid;
	grid-template-columns: 20% 70%;
	grid-gap:5%;
}

.sma-second-container span {
	margin-bottom:1px;
	display:block;
}

.sma-references{
	font-size:14px;
	margin-right:100px;
	grid-area:references;
}

.sma-left-links-container{
	grid-area:leftLinks;
}

.sma-items{
	grid-area:items;
}
.sma-title {
	display:inline;
	margin-bottom:70px;
	width:100%;
	border-bottom: 1px solid black;
	font-size:18px;
	font-weight:bold;
}

.sma-title img {
	height:10px;
	margin-top:13px;
	display:inline;
	float:right;
}




.sma-leftLinks {
		position:relative;
	z-index:1000;
	margin-left:40px;
	margin-bottom:10px;
	font-size:14px;
}

.further-row > div > div{
	display:grid;
	grid-template-columns:15% 1fr;
	grid-gap:50px;
	margin-bottom:50px;
	align-items:center;
	justify-content:center;
}

.further-row > div > div > a {
	color:black;
	font-weight:bold;
}



.table-first-row{
	background: rgba(255, 255, 255, 0.5);
}

.sma-table td, th {
	padding:10px;
}

.sma-table ul{
	margin-left:-20px;
}

.sma-table {
	margin-top:50px;
}

@media (max-width: 999px) { 

	.sma {
		height:100%;

	}
	.sma-words {
		padding: 10% 0%;
	}
	

	.sma-items p{
		font-size:3vw;
	}

	.sma-title {
		font-size:4vw;
	}

	.sma-references div {
				font-size:3vw;
	}

	.sma-references b {
		font-size:4vw;
	}
		.sma-words > h1 {
			font-size:10vw;
		    text-align:center;
		}
		.sma-first-links {
			grid-gap:4%;
			margin-left:5%;
		}
		.sma-first-links div{
			font-size:2.5vw;
		}

		.sma-color{
			padding-top:10%;
			justify-content:inherit;
			padding-left:2%;
			padding-right:2%;
		}

		.sma-dots-image{
			display:none;
		}

		.sma-second-container{
			grid-template-columns: 1fr;
			padding:5%;
			grid-template-areas: 
      		"items"
      		"references";
		}
		.sma-left-links-container{
		display:none;
	}

	.sma-table td, th{
		padding: 3px;
	}
	.sma-table {
		margin-top:20px;
		font-size:10px;
		margin-left:3%;
		margin-right:3%;
	}
	.sma-third-container{
		grid-template-columns:1fr;
	}

	.sma-wave{
		display:none;
	}

	}
