
.home-link {
	color:blue;
}

.volunteer-cms-container {
	margin:60px;
}

.volunteer-ta {
	height:30px;
	width:75%;
	margin-right:15px;

}

.volunteer-cms-subcontainer {
	display:grid;

}

.volunteer-replace-btn{
	width:200px;
}

.volunteer-link-btn {
	width:20%;
}