
.home-link {
	color:blue;
}

.ac-container {
	margin:60px;
}

.ac-ta {
	height:300px;
	width:100%;
}

.ac-subcontainer {
	display:grid;
	width:70%;
}

.ac-box-pictures {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:400px;
	display:grid;
	padding:30px;
}

.ac-box-price {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:150px;
	display:grid;
	padding:30px;
	margin:30px 0px;
}

.ac-cover-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	padding:30px;
	height:200px;
}

.ac-pictures-row {
	display:grid;
	grid-gap:50px;
	grid-template-columns:1fr 1fr 1fr 1fr;
	margin: 50px 0px;
}

.ac-box-item {
		border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:2400px;
	display:grid;
	padding:30px;
	margin:30px 0px;
}

h5 {
	border-bottom: 1px solid #C4C4C4
}
.ac-image {
	height:100px;
	margin-bottom:20px;
}

.ac-ta {
	width:100%;
	height:50px;
}

.ac-desc-ta {
	width:100%;
	height:400px;
}


