@import url(//fonts.googleapis.com/css2?family=Avenir);
@import url(//fonts.googleapis.com/css2?family=Noto+Sans);
:root {
  --main-width: 1000px;
}
@media (max-width: 999px) { 
  :root {
    --main-width: 100%;
  }
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(/static/media/Nunito-Regular.d2e691bc.ttf) format('truetype');
}

h1{
  font-family:'Nunito';
  font-weight:900 !important;
  font-style: normal;
}
span {
  display:block;
  margin-bottom:20px;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Nunito", "Noto Sans"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x :hidden;
}

html,body
{
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p + p {
  margin-top: 0;
}

.pointer {
  cursor:pointer;
}

.relative {
  position: relative;
}

button {
  float:right;
}

@media (max-width: 999px) { 
  h1{
    font-size:8vw;
  }

  div{
    font-size:4vw;
  }
  }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.box-container {
	display:grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap:30px;
}

.box{
	border: 1px solid #E0E0E0;
	box-sizing: border-box;
	padding:20px;
}

.home-container {
	margin:100px;
}


::-webkit-input-placeholder {
   text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
   text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
   text-align: center;  
}

:-ms-input-placeholder {  
   text-align: center; 
}

.contact-container {

	width:100%;
	margin-top:100px;
	margin-bottom:100px;
	background-color:white;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:left;
}

.contact-sub{
	width: var(--main-width);
}

.contact-container-yellow {

	width:100%;
	padding-top:100px;
	padding-bottom:100px;
	background-color:#F6CA45;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:left;

}



.contact-container h1 {
	font-weight:900;
	font-family:Nunito;
	color:black;
}

.contact-container-yellow h1 {
font-weight: 900;
font-size: 60px;
	font-family:Nunito;
	color:black;
}

.contact-row {
	display:grid;
	grid-template-columns: 1fr 1fr;
}

.contact-subcontainer {
	width:100%;
	padding:3% 0%;

	padding-right:10%;

	border-right: 1px solid white;
}

.contact-first-row {
	display:grid;
	grid-template-columns:1fr 1fr;
	grid-gap:3%;
	margin-bottom:30px;
}
.contact-first-row input {
	width:100%;
	padding:1%;
	height:50px;
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	border-radius: 5px;
}


.contact-message {
	width:100%;
	height:140px;
		border-radius: 5px;
}

.contact-details{
	margin-left:10%;
	margin-right:10%;
	margin-top:100px;

}

.contact-thankyou {
	display:grid;
	align-content:center;
	justify-content:center; 
	text-align:center;
}

.contact-thankyou img{
		margin-left:40%;
	margin-right:40%;

}

.contact-button-container {
	margin-top:10px;
	text-align:left;
}

.contact-button {
	width:500px;
}

.thanks {
	width:100px;
}

.btn-contact2{
	color:#ffffff !important;
	background-color: #245798 !important;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	border-radius: 7px;
	width:200px;
	padding: 12px !important;
}

	.contact-map {
		margin-top:5%;
	}

	.contact-details-row {
		margin-bottom:17px;
	}

.message-redbox{
	background:#EB5757;
	font-color:white;
	color:white;
	padding:10px;
	margin-bottom:5px;
	width:60%;
}

@media (max-width: 999px) { 

	.contact-container-yellow h1{
		font-size:8vw;
	}

	.contact-container-yellow {
		justify-content:inherit;
		padding-left:5%;
		padding-right:5%;
	}

	.contact-container h1{
		font-size:8vw;
	}

	.contact-container {
		justify-content:inherit;
		padding-left:5%;
		padding-right:5%;
	}

	.contact-row {
		grid-template-columns:1fr;
	}

	.contact-row div {
		font-size:3vw;
	}

	.contact-subcontainer {
		padding-right:0%;
		border-right: 0px solid white;
				font-weight:600;
	}

	.contact-subcontainer input {
		height:5vh;
	}

	.btn-contact2 {
		width:100%;
	}

	.contact-first-row{
	}

	.contact-button {
		width:100%;
	}

	.contact-details {
		margin-top:30px;
	}

	.contact-details-row{
		margin-bottom:0px;
	}



}
.nav-top{
     position:fixed !important;
     width:100%;
}

.header-logo {
	float:left;
	width: 87px ;
	height: 103px;
}

.btn-donation2{
	color:#ffffff !important;
	background-color: #245798 !important;
	margin-top:-7px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	border-radius: 7px;
	height:40px;
	padding: 0px 30px !important;
}

.active-link{
	padding:0px 8px;
	font-weight: 900;
	color:rgba(0,0,0,1) !important;

	border-bottom:1px solid black; 
}

.active-about-class{
	font-weight: 900;
	font-color: black;
	margin-right:0px;
	padding-right:0px;
	border-bottom:1px solid black; 
	width:110px;
		margin-right:40px;
			color:rgba(0,0,0,1) !important;
}

.active-about-class a {
	color:rgba(0,0,0,1) !important;
}

.nav-css{
	background-color:black;
	height: 60px,
}

.sma-nav {
	width:100%;
}

.sma-nav a {
	height:30px;
	color: rgba(0,0,0,.5);
	margin-right:40px;
	font-size:16px;
	text-transform: uppercase;
	font-family:'Nunito';
}


.active-about {
	color:red;
}

.dropdown{
	margin-top:-7px;
}

.header-x{
	display:none;
}

.mobile-donate{
	display:none;
}

@media (max-width: 990px) {

	.nav-css{
		height:100%;
	}

		.header-logo{
		height:30px;
		width:24px;
	}

	.navbar-toggler-icon {
		margin-bottom:0px;
	}

	.sma-words{
		padding-top:20%;
	}
	.sma-nav{
		position:absolute;
		width: 105%;
    background-color: white;
    padding: 40px;
    padding-top:10%;
    margin-top: -13%;
    margin-left: -5%;
	}

	.header-x{
		 cursor:pointer;
		width:20px;
		float:right;
		display:block;
	}

	.mobile-donate{
		display:block;
		float:right;
	}

	.navbar {
		display: inline;
		grid-gap:10px;
		float:right;
	}


	.navbar button {
		margin-top:2px;
	}

	.navbar-toggler {
		width:55px;
		float:right;
		margin-left:10px;
	}
	span {
		margin-bottom:0px;
	}

	.lg-donate{
		display:none;
	}

	.sma-nav a {
		text-align:center;
		margin-bottom:6%;
		height:100%;
		margin-right:0px;
	}

	.active-link {
		padding:0px;
	}
	.dropdown {
		margin-top:-3%;
	    margin-bottom:-3%;
	    margin-left:3%;
	}

	.nav-item{
		width:100%;
	}

	.active-about-class{

	border-bottom:0px solid black; 
	}

  /* CSS that should be displayed if width is equal to or less than 800px goes here */
}
.footer {
	background: #102034;
	height:350px;
	display:grid;
	grid-template-columns:1fr 1fr 1fr 1fr;
	padding:60px;
	color:#ffffff;
}


.footer a {
	color:#ffffff;
}

.footer-icon {
	width:30px;
	margin-right:70px;
}

.footer-links {
	display:grid;
	align-content:flex-start;
	grid-template-columns:1fr;
	grid-gap:10px;
}

@media (max-width: 990px) {

	.footer{
		grid-template-columns:25% 30% 1fr;
		height: 100%;
		grid-gap:3px;
		padding-left:5%;
		padding-right:5%;
	}

	.footer a{
		font-size: 3vw;
	}
	.footer p{
		font-size: 3vw;
	}
	.footer-icon-rows{
		display:grid;
		grid-template-columns:1fr 1fr;
		margin-top:-70px;

	}

	
}
.landing-container{
    display:grid;
    grid-template-columns:1fr;

    margin:5% 25%;
    padding:5% 10%;
    border:1px solid grey;
}

.landing-subcontainer{
    display:grid;
    grid-template-columns: 30% 1fr;
    grid-gap:5%;
}

.landing-logo {
    width:146px;

}

.gopher{
 display: block;
 max-width: 100%;
 height: auto;
}

.landing-label{
    float:left;
    text-align:left;
}

.landing-input {
    height:30px;
}

.landing-button{
    border:0;
    margin-top:5%;
    margin-left:auto;
    margin-right:auto;
    width:40%;
    background:#2F80ED;
    color:white;
    height:60px;
    font-size:large;
}
.home-link {
	color:blue;
}

.donor-container {
	margin:60px;
}

.donor-ta {
	height:300px;
	width:100%;
}

.donor-subcontainer {
	display:grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap:50px;
}
.confirmation-container {

    position: fixed;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    width:300px;
    height:300px;
    border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display: grid;
	align-content:center;
	justify-content:center;
	background-color:white;
}

.checkmark {
	text-align:center;
	color:green;
	font-size:100px;
}

.sentence {
	text-align:center;
}
.home-link {
	color:blue;
}

.special-container {
	margin:60px;
}

.special-ta {
	height:300px;
	width:100%;
}

.special-subcontainer {
	display:grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap:50px;
}

.special-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	align-content:center;
	padding:10%;
	justify-content:center;
}

img {
	max-width:100%;
	height: auto;
}

button {
	margin-top:10px;
}


.home-link {
	color:blue;
}

.news-container {
	margin:60px;

}

.news-ta {
	height:300px;
	width:100%;
}

.news-subcontainer {
	display:grid;
	grid-template-columns: 70% 1fr;
	grid-gap:50px;
}

.news-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	padding:30px;
	margin:20px 0px;
}

.an-links{
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	grid-template-columns:1fr 1fr 1fr 1fr;
	text-align:right;
	padding-right:10px;
}

.an-dropdown{
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	justify-content: center;
	align-content:center;
	padding:30px;

}

.an-links > a {
	color:#4F4F4F;
	margin:3px;

}

.an-links > a:hover {
	color:#4F4F4F;
}
.cover-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:500px;
	display:grid;
	padding:30px;
}


.language-box {
	width:100%;

}

.header-ta {
	width:100%;
}

.content-ta {
	width:100%;
	height:400px;
}

.image-button {
	height:50px;
}

.image-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:200px;
	display:grid;
	padding:30px;
}

.an-checkbox{
	display:grid;
	grid-gap:5px;
	margin-top:30px;
}
.mn-subcontainer {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	width:100%;
	padding:30px;
}

.mn-year-container{
	display:grid;
	grid-template-columns: 15% 1fr;
	align-items:center;
	margin-bottom:30px;

}
.mn-row {
	display:grid;
	grid-template-columns: 15% 20% 25% 10% 8% 8% 4%;
	grid-gap:15px;

}

.mn-container {
    margin:60px;
}

.home-link {
	color:blue;
}

.mn-content{
	width:100%;
	  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.edit-link{
	color: #53B7D5;
}

.delete-link {
	color: #EB5757;
}

.star-link {
	color:#F6CA45;
	font-size:16px;
}

.star-white-link {
	font-size:16px;
}

.home-link {
	color:blue;
}

.ql-editor h1,h2,h3{
	color:black;
}

.news-container {
	margin:60px;
}

.news-ta {
	height:300px;
	width:100%;
}

.an-links{
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.news-subcontainer {
	display:grid;
	grid-template-columns: 70% 1fr;
	grid-gap:50px;
}

.news-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;

	display:grid;
	padding:30px;
}

.cover-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:500px;
	display:grid;
	padding:30px;
}


.language-box {
	width:100%;

}

.header-ta {
	width:100%;
}

.content-ta {
	width:100%;
	height:400px;
}

.image-button {
	height:50px;
}

.image-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:200px;
	display:grid;
	padding:30px;
}


.home-link {
	color:blue;
}

.patient-container {
	margin:60px;
}

.patient-ta {
	height:30px;
	width:50%;
	margin-top:20px;

}

.patient-subcontainer {
	display:grid;
	grid-template-columns: 70% 1fr;
	grid-gap:50px;
}

.patient-container button {
	float:left;
}

.patient-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	padding:30px;
}

.patient-checkbox {
	margin-left:20px;
}

.new-form-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	padding:20px;

}

.form-row {
	margin:30px;
	display:grid;
	grid-template-columns:1fr;
}

.replace-btn {
	width:50%;
}

.edit-font {
	color:green;
}

.edit-font:hover {
	color:green;
}

.tag {
	margin-left:10px;
	display:inline-grid;
		border: 1px solid #C4C4C4;
	box-sizing: border-box;
	padding:5px 30px;
}

.edit-btn {
	margin-top:-500px;
	margin-left: 20px;
}

.edit-form {
	margin-bottom:20px;
}

.replace-btn {
	width:200px;
}

.inline {
	display: inline
}
.home-link {
	color:blue;
}

.ma-container {
	margin:60px;
}

.ma-container button {
	float:left;
}
.ma-btn {
	width:400px;
	text-align:left;
}

.ma-box {
	background: #F2F2F2;
	padding:10px;
	margin:10px;
	display:grid;
	grid-template-columns:1fr 8% 8%;
	border: 1px solid #E0E0E0;
	box-sizing: border-box;
	width:400px;
	text-align:left;

}

.ma-box > a {
	color:black;
	font-weight:bold;
}

.ma-edit-link {
	text-align:right;
	color: #6FCF97;
}

.ma-x {
	text-align:right;
}

.home-link {
	color:blue;
}

.na-container {
	margin:60px;
}

.na-ta {
	height:300px;
	width:100%;
}

.na-subcontainer {
	display:grid;
	grid-template-columns: 70% 1fr;
	grid-gap:50px;
	margin:30px 0px;
}

.na-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:700px;
	display:grid;
	padding:30px;
}

.na-cover-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:500px;
	display:grid;
	padding:30px;
}


.na-language-box {
	width:100%;

}

.na-author-ta {
	width:100%;
}

.na-details-ta {
	width:100%;
}

.na-note-ta {
	width:100%;
	height:370px;
}

.na-image-button {
	height:50px;
}

.na-image-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:250px;
	display:grid;
	padding:30px;
}


.home-link {
	color:blue;
}

.org-container {
	margin:60px;
}

.org-ta {
	height:300px;
	width:100%;
}

.org-staff-row{
	display:grid;
	grid-template-columns:30% 30% 30%;
	  grid-gap:20px;
	  margin-bottom:30px;

}
.org-subcontainer {
	margin:30px 0px;
}

.org-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	padding:30px;
}


.org-language-box {
	width:100%;

}

.org-name-ta {
	width:100%;
	height:30px;
}

.org-names-ta {
	width:100%;
	height:100px;
}


.org-title-ta {
	width:100%;
	height:50px;
}

.org-row {
  display:grid;
  grid-template-columns:30% 30% 30%;
  grid-gap:20px;
  margin-bottom:30px;
  padding-bottom:30px;
  border-bottom: 1px grey solid
}

.home-link {
	color:blue;
}

.uc-container {
	margin:60px;
}


.uc-image-button {
	width:30%;
}

.uc-cover-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	padding:30px;
}


.uc-image-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;

	width:60%;
	display:grid;
	padding:30px;
}


.home-link {
	color:blue;
}

.volunteer-cms-container {
	margin:60px;
}

.volunteer-ta {
	height:30px;
	width:75%;
	margin-right:15px;

}

.volunteer-cms-subcontainer {
	display:grid;

}

.volunteer-replace-btn{
	width:200px;
}

.volunteer-link-btn {
	width:20%;
}
.mc-subcontainer {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	width:100%;
	padding:30px;
}

.mc-row {
	display:grid;
	grid-template-columns: 15% 20% 25% 15% 15%;
	grid-gap:15px;

}

.mc-container {
    margin:60px;
}

.mc-title {
	display:grid;
	grid-template-columns: 30% 37% 15% 15%;
	grid-gap:1%;
	margin:30px 0px;
}

.mc-button {
	display:grid;
	justify-content:right;
}

.mc-links-container{
	display:grid;
	grid-template-rows:20% 20%;
	grid-gap:10px;
}
.home-link {
	color:blue;
}


.edit-link{
	color: #53B7D5;
}

.delete-link {
	color: #EB5757;
}

.mc-tag{
	background: #FFFFFF;
/* Gray 5 */

border: 1px solid #E0E0E0;
box-sizing: border-box;
flex: none;
order: 0;
align-self: center;
flex-grow: 0;
margin: 10px 0px;
text-align:center;
width:80%;
  border-radius: 5px;
}


.home-link {
	color:blue;
}

.ac-container {
	margin:60px;
}

.ac-ta {
	height:300px;
	width:100%;
}

.ac-subcontainer {
	display:grid;
	width:70%;
}

.ac-box-pictures {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:400px;
	display:grid;
	padding:30px;
}

.ac-box-price {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:150px;
	display:grid;
	padding:30px;
	margin:30px 0px;
}

.ac-cover-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	padding:30px;
	height:200px;
}

.ac-pictures-row {
	display:grid;
	grid-gap:50px;
	grid-template-columns:1fr 1fr 1fr 1fr;
	margin: 50px 0px;
}

.ac-box-item {
		border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:2400px;
	display:grid;
	padding:30px;
	margin:30px 0px;
}

h5 {
	border-bottom: 1px solid #C4C4C4
}
.ac-image {
	height:100px;
	margin-bottom:20px;
}

.ac-ta {
	width:100%;
	height:50px;
}

.ac-desc-ta {
	width:100%;
	height:400px;
}






.intro {
	z-index:-1;
	background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#F6CA45;
	display:grid;
	grid-template-columns: 30% 1fr;
}

.main-small{
	margin-top:25px;
	font-size: 12px;
}

.white-text {
	color:#ffffff;
	font-size:18px;
}

.super-small-words{
	font-size:10px;
}

.clip-circle {
  -webkit-clip-path: circle(40% at center);
          clip-path: circle(40% at center);
	grid-area: image;
}

.intro-img{
	z-index:-10;
	display:block;
}

.intro-mobile-img{
	display:none;
}
.intro-words{
	display:grid;
	grid-template-rows:1fr;
	grid-template-columns:1fr;
	align-self:center;
	margin-left:20%;
	margin-right:20%;
}

.title {

}

.title-words > h1 {

	font-size:48px;
}

.title-words {
	margin-top:-12px;

}
.main-logo {
	float:left;
	height: 100px;
	margin-right:40px;
}

.intro-footer {
	display: grid;
	grid-template-columns: 35% 45% 15%;
	grid-gap:10px;
}

.hkcss-image{
	margin-top:0px;
}

h1{
	font-size:54px;
	color:#ffffff;
}
img { max-width: 100%; height: auto }


.intro-second{
	background-color:#F6CA45;
	margin-top:-100px;
	height:100%;

}

.stupid-scroll-container{
	margin-left:48%;
	margin-right:48%;
	position:relative;
	display:grid;
	align-items:center;
	justify-content:center;
}
.stupid-scroll{
	z-index:10000;
	position:absolute;
	margin-top:100px;
	color:white;
	display:grid;
	align-items:center;
	justify-content:center;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
}
.stupid-scroll-line{
		z-index:10000;
	position:absolute;
	margin-left:25px;
		margin-top:200px;
	height:50px;
	width:0px;
	border-right:3px solid white;
}
.intro-second-image{
	width:100%;
}


.second {
	background:#1A3C66;
	display:grid;
	align-content:center;
	justify-content: center;
}



.second-sub{
		display:grid;
	grid-template-columns: 50% 1fr;
	align-content:center;
	justify-content:center;
	height:700px;


	grid-template-areas: 
      "image words"
      "header header";
	width: var(--main-width);
}

.second-words{
	display: grid;
	align-content:center;
	margin-right:10%;
	font-size:24px;

	grid-area:words;
}

.second-words > p {
	font-size:16px;
}

.second-words > h1 {
	font-size:44px;
}
.second-link > a{
	font-weight:bolder;
	color:#F6CA45;
}
.circle-dots-parent{
	position:relative;
}
.circle-dots{
	position:absolute;
	margin-top:-80px;
	left:-60%;
	height:200px;
}

.reg-dots-parent {
	position:relative;
}
.reg-dots{
	position:absolute;
	left:-50%;
	margin-top:-80px;
	height:350px;
}

.ocean-wave-parent {
	position:relative;
}

.ocean-wave{
	position:absolute;
	right:-60%;
	height:150px;
		margin-top:-50px;
}
.third {
	padding-top:70px;
	padding-bottom:70px;
	font-family:Nunito;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:center;
}

.third-sub{

	width: var(--main-width);
}
.third-title {
	margin-left:20%;
	margin-right:20%;
	text-align:center;
	color:#102034;
	font-size:54px;
	font-family: Nunito;
	font-weight: bolder;

}

.third-text {
	margin-left:25%;
	margin-right:25%;
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 160%;
	color: #102034;
}

.third-link {
	font-family: Nunito;
	font-style: normal;
	font-weight: 900;
	font-size: 18px;
	line-height: 151%;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #53B7D5;

}

.index-down-arrow {
	width:30px;
}

.orange-arrow {
	width:30px;
}
.index-up-arrow {
	width:30px;
}

.third-container {
	width:100%;
	display: grid;
	grid-template-columns:1fr 1fr 1fr;
	grid-gap:15px;

}

.third-content {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 160%;


	text-align: center;


	margin:15%;
	color: #102034;
}

.third-content > img {
	margin-bottom:10%;
}

.white-arrow{
	height:25px;
}

.fourth {
	padding-top:70px;
	padding-bottom:150px;
	background-color:#A1CC48;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:center;
}



.fourth-sub {
	width: var(--main-width);
}
	.fourth-content iframe {
						height:364px;
		width:485px;
		border:0px;
	}
.fourth-title{
	text-align:left;
	font-family: Nunito;
	font-style: normal;
	font-weight: 900;
	font-size: 54px;
	line-height: 75px;

	color: #072142;
}

.fourth-container{

	display:grid;
	grid-template-columns:1fr 1fr;
	grid-gap:30px;
	text-align:left;
	margin-bottom:50px;

}

.fourth-text {

	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 160%;
	color: #102034;
}

.fourth-date {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 151%;
	color: #F2F2F2;
}

.fourth-link-container {
	display:grid;
	grid-template-columns:1fr;
}

.fourth-link {
	text-align:center;
	font-family: Nunito;
	font-style: normal;
	font-weight: 900;
	font-size: 18px;
	line-height: 151%;
	/* or 27px */

	letter-spacing: 0.1em;
	text-transform: uppercase;

	color: #FFFFFF;
}

.fourth-fifth{
	background-color:#A1CC48;
	margin-top:-100px;
	height:100%;

}

.fourth-image {
	margin-bottom:5px;
	width:100%;
	display:grid;
	align-items:center;
	justify-content:center;
}


.fourth-fifth-image{
	width:100%;
}

.fifth {
	padding: 80px 100px 80px 100px;
	background-image:url('/fifth-bg.png');
	    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:center;
}





.fifth-sub{
	width: var(--main-width);
}
.fifth-title {
	text-align:left;
	font-family: Nunito;
	font-size: 54px;
	font-weight:900;
	color: #ffffff;
}

.fifth-container{
	display:grid;
	grid-template-columns:1fr 1fr 1fr;
	grid-gap:20px;
}


.fifth-content {
	border-radius: 10px;
	text-align:center;
	background-color:#ffffff;
}

.fifth-text{
	margin-top:30px;
	margin-bottom:30px;
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 151%;
	/* or 23px */

	text-align: center;

	/* Dark grey */

	color: #8D8D8D;
}

.fifth-image {
	margin-left:5%;
	width:90%;
	margin-right:5%;
	display:grid;
	align-items:center;
	justify-content:center;
}

.fifth-circle-dots{
	position:absolute;
	left:105%;
	margin-top:-20px;
	height:250px;
}

.fifth-image-container{
	height:120px;
}

.sixth {
	padding:100px 0px;
	background-color:#ffffff;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:center;
}

.sixth-sub{
	width: var(--main-width);
}

.sixth-title-container {
	display:grid;
	grid-template-columns:1fr;
}
.sixth-title {
	text-align:center;
	font-family: Nunito;
	font-style: normal;
	font-weight: 900;
	font-size: 54px;
	line-height: 75px;
	text-align: center;

	color: #072142;
}

.sixth-container {
	margin-left:5%;
	margin-right:5%;
	text-align:left;
	display:grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap:20px;
}

.sixth-text {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 24px;
	/* or 160% */


	color: #102034;
}

.seventh {
	display: grid;
	justify-content:center;
	padding-top:100px;
	padding-bottom:80px;
	background-color:#102034;
}

.seventh-title-container{
	display:grid;
	grid-template-columns:1fr;
}

.seventh-title {
	font-family: Nunito;
	font-style: normal;
	font-weight: 900;
	font-size: 55px;
	line-height: 75px;
	text-align: center;

	/* White */

	color: #FFFFFF;
}

.second-fluff {
	grid-area:header;
}

@media (max-width: 999px) { 
  .intro {
    	grid-template-columns: 40% 1fr;
  }

  .intro-second{
  	margin-top:-10%;
  }

  	.intro-img{
	z-index:-10;
	display:none;
}

  .intro-words{
  	padding-top:15%;
  	margin-right:0%;
  	margin-left:0%;
  }
.intro-mobile-img{
	z-index:-10;
	display:block;
}

.intro-footer {
	display: grid;
	grid-template-columns: 1fr;
	margin-left:30px;
	color:#245798;
}

.hkcss-image{
	display:none;
}
  .middle{
  	margin-top:-5%;
  	margin-left:-8%;
  }
  .white-text{
  	font-size:3vw;
  }
  .main-small{
	margin-top:2%;
	font-size: 2vw;
	}

	.title-words > h1 {

	font-size:6vw;
	}

	.main-logo{
		display: none;
	}

	.reg-dots{
		position:absolute;
		left:-20%;
		margin-top:-10px;
		height:270px;
	}

	.circle-dots{
		position:absolute;
		margin-top:-130%;
		left:10%;
		height:120px;
	}

	.ocean-wave {
		margin-top:-150px;
		opacity: 0.2;
	}

	.stupid-scroll{
		margin-top:0px;

	}
	.stupid-scroll-line{

		margin-top:100px;

	}

	.second-sub{
		display:grid;
		grid-template-columns: 70% 1fr;
		align-content:center;
		justify-content:center;
		height:700px;
		width: var(--main-width);

		grid-template-areas: 
	      "image header"
	      "words words";
	}

	.clip-circle {
	  -webkit-clip-path: circle(40% at center);
	          clip-path: circle(40% at center);


	}


	.second-image {

	}
	.second-words {
		margin-top:10%;
		margin-left:30%;
		margin-right:10%;
		width:60%;
	}

	.second-words h1 {
		font-size:8vw;
	}

	.second-words > p {
		font-size:12px;
	}

	.third-title {
				font-size:8vw;
		margin-left:10%;
		margin-right:10%;
	}

	.third-text {
		margin-left:10%;
		margin-right:10%;
		font-size:14px;
	}

	.third-link {
		font-size:14px;

	}

	.third-container {
			display: grid;
	grid-template-columns: repeat( 4, 1fr );
	}

	.third-content {
		font-size:3vw;
			grid-column: span 2;
	}

	.third-content:nth-last-child(1):nth-child(odd) {
	grid-column: 2 / span 2;
	}

	.fourth-container{

	display:grid;
	grid-template-columns:1fr;
	grid-gap:30px;
	text-align:center;
	margin-bottom:50px;

	}

	.fourth-content{
				display:grid;
		justify-content:center;
	}



	.fourth-content iframe {
		padding-left:5%;
		padding-right:5%;
		height:300px;
		width:400px;
		border:0px;
	}

	.fourth-image{
		margin-left:auto;
		margin-right:auto;
		height:300px;
		width:400px;
	}

	.fourth-image, .fourth-text{
		font-size:4vw;
		padding-left:5%;
		padding-right:5%;
	}

	.fourth-title{
		text-align:center;
		font-size:8vw;
		
	}

	.fifth-title {
		text-align:center;
		font-size:8vw;
	}

	.fifth-container {
		grid-template-columns: 1fr 1fr;
		grid-gap:15px;
	}

	.fifth-text{
		margin-bottom:10px;
		margin-top:10px;
		font-size:13px;
	}

	.fifth-image-container{
		height:50%;
	}
	.fifth {
		padding:40px 15px;
	 	padding-bottom:30px;
	}

	.sixth {
		padding:40px 0px;
	}

	.sixth-container {
		grid-template-columns: 1fr 1fr;
		grid-gap:5px;
	}

	.sixth-title {
		font-size:9vw;
	}

	.sixth-text {
		font-size: 3vw;
		margin-left:4%;
	}
	.seventh {
		padding-top:40px;
	}
	.seventh-title {
		padding:5% 0%;
		font-size:8vw;
	}

	.stupid-scroll-container{
		display:none;
	}


}

@media(max-width:450px){
		.fourth-content iframe {
		padding-left:5%;
		padding-right:5%;
		height:225px;
		width:300px;
		border:0px;
	}

	.fourth-image{
		margin-left:auto;
		margin-right:auto;
		height:225px;
		width:300px;
	}
}
.d-container {

	width:600px;
	padding-top:10px;
	background-color:white;
	padding-left:30px;
	padding-right:30px;
	padding-bottom:50px;
	box-shadow: 0px 0px 10px rgba(131, 131, 131, 0.25);
}

.d-text{
	margin-top:30px;
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 160%;
	/* or 29px */


	color: #102034;

}

.d-info {
	margin-left:10px;
}

.d-small-text{
	margin-top:20px;
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 160%;
	/* or 29px */


	color: #102034;

}

.other-btn{
	margin-top:10px;
	border: 1px double #53B7D5;
	box-sizing: border-box;
	border-radius: 3px;
	height:50px;
	width:120px;
	padding:10px;
}


.d-four-columns {
	display:grid;
	grid-template-columns:1fr 1fr 1fr 1fr;
	grid-gap:25px;
}

.d-two-columns {
	display:grid;
	grid-template-columns:1fr 1fr;
	grid-gap:20px;
}

.d-receipt-row {
	display:grid;
	grid-template-columns:8% 1fr;
	justify-content:center;
	align-items:center;
	margin-top:30px;
}

.receipt-checkbox{
	margin-left:10px;

}





.d-receipt-text {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: 600;
	font-size:15px;
	margin-left:2%;
	line-height: 151%;
	/* or 23px */


	color: #000000;
}

.d-last-row{
	display:grid;
	grid-template-columns:1fr;
}

.btn-info{
	height:50px;
	font-size:14px;
}

.btn-outline-info{
	font-size:14px;
	height:50px;
}
.btn-donation{
	color:#ffffff !important;
	background-color: #245798 !important;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 7px;
	height:60px;
}

.donation-number-row{
	margin-top:20px;
	padding-left:5%;
	padding-right:5%;
	display:grid;
	grid-template-columns: 2% 1fr;
	grid-gap:30px;
	margin-bottom:20px;
}

.donation-number {
	color: #8D8D8D;
	font:24px;
}

.donation-or {
	margin-left:120px;
}

.pdf-icon {
	width:20px;
	margin-right:15px;
}

.d-icon {
	width:80px;
}
.donation-icon-row{
	margin-top:20px;
	padding-left:5%;
	padding-right:5%;
	display:grid;
	grid-template-columns: 10% 1fr;
	grid-gap:30px;
	margin-bottom:20px;
}

.paypal-thankyou{
	margin-top:30px;
	text-align:center;
}

.paypal-thanks-image {
	margin-bottom:30px;
	width:100px;
}

@media (max-width: 999px) { 

	.d-container {
		width:90%;
		margin-left:5%;
		margin-right:5%;
	}

	.d-four-columns{
		grid-gap:5px;
	}

	.d-four-columns Button{
		padding:0px;
	}

	.d-receipt-text{
			font-size:3vw;
	}

	.d-text {
		font-size:4vw;
	}

	.d-small-test {
		font-size:3vw;
	}

}
.about-color {
	position:relative;
	z-index:-100;
		background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#53B7D5;
}

.about {

	display:grid;
	height:700px;

	align-content:center;
	justify-content:center;
}


.about-sub{
	display:grid;
	grid-template-columns: 60% 1fr;
	grid-gap:0px;
	width: var(--main-width);
}


.intro-about-ill{
	z-index:-1;
	position:absolute;
	left:70%;
	margin-top:10%;
	height:580px;

}

.intro-about-dots{
	z-index:-1;
	position:absolute;
	left:-1%;
	margin-top:22%;
	height:250px;

}

.intro-about-wave{
		z-index:1200;
	position:absolute;
	left:80%;
	height:200px;
}


.about-pic{
	position:relative;
	z-index:1000;
}

.intro-second-image{
	position:relative;
	z-index:1000;
}
.about-intro-second{
	position:relative;
	z-index:1000;
	margin-top:-100px;
	height:100%;
}

.about-words {
	padding:100px;
	width:100%;
}

.about-words > h1 {
	font-size:60px;
}
.about-words > p {
	width:90%;
	color:#ffffff;
}

.about-second {
	background:#1A3C66;
	display:grid;
	align-content:center;
	justify-content: center;
	padding-bottom:5%;
}


.about-clip-circle {
  -webkit-clip-path: circle(40% at center);
          clip-path: circle(40% at center);
	grid-area: image;
}

.about-second-fluff {
	grid-area:header;
}

.about-second-sub{
		display:grid;
	grid-template-columns: 50% 1fr;
	align-content:center;
	justify-content:center;
	height:700px;
	width: var(--main-width);

	grid-template-areas: 
      "image words"
      "header header";
}



.about-second-words {
	display: grid;
	align-content:center;
	margin-right:10%;
	color:white;
	grid-area:words;
}

.about-second-words > h1 {
	margin-bottom:30px;
}

.about-second-words > ul {
	margin-left:-20px;
}

.about-third {
	padding-top:70px;
	padding-bottom:70px;
	display:grid;
	align-content:center;
	justify-content: center;

	text-align:center;
}

.about-third-title {
	display:grid;
	grid-template-columns:50% 1fr;
	text-align:left;
	margin-bottom:30px;

}

.about-third-sub {
	width: var(--main-width);
}

.about-third-h1 {
	color:black;
	font-size:74px;
	font-weight:900;
		font-family:Nunito;
}

.about-third-row {
	display:grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap:15px;
	text-align:left;
}

.about-third-item {
	display: grid;
	grid-column: span 2;
	grid-template-columns: 20% 1fr;
	grid-gap:15px;
}


.about-third-item:nth-last-child(1):nth-child(odd) {
	grid-column: 2 / span 2;
}


.about-fourth {
	padding-top:70px;
	padding-bottom:70px;
	background: #F6CA45;
	text-align:left;
	margin-bottom:30px;
display:grid;
	align-content:center;
	justify-content: center;
}

.about-fourth-sub {
	width: var(--main-width);
}


.about-fourth-title {
	display:grid;
	grid-template-columns:50% 1fr;
	text-align:left;
	margin-bottom:30px;

}

.about-fourth-forms {
	display:grid;
	grid-template-columns:40% 40%;
	text-align:left;
	grid-gap:30px;
	margin-bottom:10px;

}

.about-fourth-h1 {
	font-family:'Nunito';
	color:black;
	font-size:48px;
	font-style: normal;
	font-weight: 900;
	line-height: 75px;
}

.about-fifth {
	height:700px;
	padding:100px 0px;
display:grid;
	align-content:center;
	justify-content: center;
}

.about-fifth-sub {
	width: var(--main-width);
}

.about-fifth-h1 {
	font-family:'Nunito';
	color:black;
	font-size:74px;
	font-style: normal;
	font-weight: 900;
	font-size: 55px;
	line-height: 75px;
}

.about-sixth {
	padding:100px 0px;
	background-color:#1A3C66;
	display:grid;
	align-content:center;
	justify-content: center;
}

.about-sixth-sub {
	width: var(--main-width);
}


.about-sixth-first-row {
	margin-top:50px;
	display:grid;
	grid-template-columns:1fr 1fr;
	justify-content:top;
	align-content:top;
	text-align:center;
}

.about-sixth-row {
	margin-top:50px;
	display:grid;
	grid-template-columns:1fr 1fr 1fr;
	justify-content:center;
	text-align:center;
}

.about-sixth-last-row {
	display:grid;
	grid-template-columns:1fr 1fr 1fr;
	justify-content:center;
	text-align:center;
}
.about-sixth-item > h2 {
	text-transform:uppercase;
	font-size:16px;
	color:#F2F2F2;
	border-bottom:1px solid #F2F2F2;
	padding:10px;
	margin-bottom:10px;
	opacity: 0.8;
}

.about-fsma-staff {
	margin-top:50px;
}
.about-fsma-staff > h2 {
	margin-left:auto;
	margin-right:auto;
	text-transform:uppercase;
	font-size:16px;
	color:#F2F2F2;
	border-bottom:1px solid #F2F2F2;
	padding:10px;
	opacity: 0.8;
	text-align:center;
	width:300px;
}

.about-sixth-item {
	  margin-left: auto;
	  margin-right:auto;

		width:300px;
}
.about-sixth-first-name {
	font-size: 20px;
	color: #F9F9F9;
}

.about-sixth-first-title {
	font-weight:300;
	font-size:16px;
    color: #F9F9F9;
    opacity: 0.8;
    margin-left:10%;
    margin-right:10%;
}
.about-sixth-title {
	display:grid;
	grid-template-columns: 1fr;
	text-align:center;
	justify-content: center;
}

.about-sixth-item {
	color:white;
}

.about-seventh {
	height:1000px;
	padding:70px 0px;
	display:grid;
	align-content:center;
	justify-content: center;
}

.about-seventh-sub {
	width: var(--main-width);
}


.about-seventh-h1 {
	font-family:'Nunito';
	color:black;
	font-size:74px;
	font-style: normal;
	font-weight: 900;
	font-size: 55px;
	line-height: 75px;
}


.about-seventh-title {
	display:grid;
	grid-template-columns: 1fr;
	text-align:center;
	justify-content: center;
}

.about-seventh-image {
	padding:100px;
}
.carousel-control-next-icon{
	width:50px !important;
	height:50px !important;
	background-image: url('/rightarrow.png') !important;
}

.carousel-control-prev-icon{
	width:50px !important;
	height:50px !important;
	background-image: url('/leftarrow.png') !important;
}

.carousel-indicators {
	top:400px;
	height:20px;

}
.carousel-indicators li {
	height:0px;
	border-top:0px;
	background-color:grey !important;
}

.appreciation {
	width:74%;
	margin-left:13%;
	margin-right:13%;
}

.appreciation-card {
	display:grid;
	grid-template-columns:20% 1fr;
	grid-gap:20px;
	padding:30px;
	align-content:center;

}

.card {
	border:0px !important;
}

.card-color{
	background:#F6CA45;
	border-radius: 25px;
}

.card-color-blue{
	background:#53B7D5;
	border-radius: 25px;
}


.appreciation-title {
	text-align:center;
}
.appreciation-words {
	display:grid;
	align-content:center;
}

.form-container > div{
	display:grid;
	grid-template-columns:10% 1fr;
}

.absolute {
	position:absolute;
}


.form-link {
	margin-left:10px;
	display:grid;
}

.arrow {
	height:25px;
}
.form-link > a {
	color:black;
}

.form-link > a:hover {
	color:black;
}

@media (max-width: 999px) { 

	.about {
		height:100%;
		padding-top:40%;
		padding-bottom:40%;
	}
	.about-sub {
		grid-template-columns: 1fr 1fr;

	}

	.about-words {
		margin-top:10%;
		padding-left: 10%;
		padding-right:0%;
		padding-top:0%;
		padding-bottom:0%;
		grid-gap:5px;
	}

	.about-words > h1{
		font-size:7vw;
	}

	.about-words > p {
		width:100%;
		font-size: 3vw;
	}

	.about-pic {
		margin-top:0%;
		padding:10px;
	}

	.about-intro-second {
		margin-top:-30%;
	}
	.about-second-words{
		padding-left:20%;
		margin-right:5%;
		font-size:3vw;
	}

		.about-second-words li{
			font-size:3vw;
		}

	.about-second-words h1 {
		font-size:8vw;
	}
	.about-second-sub{
		height:100%;
		padding-top:0%;
		padding-bottom:10%;

		grid-template-areas: 
	      "image header"
	      "words words";
	}

	.about-third {
		margin-left:5%;
		margin-right:5%;
				font-size:3vw;
	}

	.about-third-title{
		grid-template-columns:1fr;

	}
	.about-third-title p{

		font-size:3.5vw;
	}
	.about-third-h1{
		font-size:8vw;
		font-weight:900;
	}

	.about-third-row{
		grid-template-columns: repeat(2,1fr);

	}

	.about-third-item-text{
		font-size:3vw;
	}
	.about-third-item:nth-last-child(1):nth-child(odd) {
		grid-column: span 2;
	}

	.about-fourth{
		padding-left:5%;
		padding-right:5%;

	}

	.about-fourth-h1 {
		font-size:8vw;
	}

	.about-fourth-title{
		grid-template-columns:1fr;
	}

	.about-fourth-title p{
		font-size:3.5vw;
	}

	.about-fourth-forms{
		grid-template-columns:1fr;
	}

	.about-fifth {
		padding:0% 5%;
		height:100%;
		margin-bottom:10%;
	}

	.about-fifth-h1{
		text-align:center;
		font-size:8vw;

	}

	.appreciation-card {
		grid-template-columns:1fr;
	}

	.appreciation-title img {
		max-width:50%;
	}

	.card-body {
		padding:0px;
	}

	.appreciation {
		width:100%;
		margin-left:0%;
		margin-right:0%;
	}

	.about-sixth h1{
		font-size:8vw;
	}

	.about-sixth {
		padding-left:5%;
		padding-right:5%;
	}

	.about-sixth-first-row{
		grid-template-columns:1fr;
	}
	.about-sixth-row{
		margin-top:0px;
		grid-template-columns: repeat(4,1fr);
	}

	.about-sixth-item{
		grid-column: span 2;
		width:80%;
		margin-bottom:10%;
	}
	.about-sixth-item p {
		font-size:3vw;
	}

	.about-sixth h2 {
		padding:5px;
		font-size:4vw;
		margin-left:auto;
		margin-right:auto;
		width:80%;
		margin-bottom:25px;
	}

	.about-sixth-item:nth-last-child(1):nth-child(odd) {
		grid-column: 2 / span 2;
	}

	.about-sixth-first-name {
		font-size:14px;
	}

	.about-members {
		width:100%;
	}

	.about-members > h2 {
		font-size:4vw;
	}

	.trustee h2 {
		height:28%;
	}

	.about-sixth-last-row {
		grid-template-columns:repeat(4,1fr);;
	}

	.about-seventh{
		height:100%;
	}

	.about-seventh-image{
		padding:1%;
	}

	.about-seventh-title{
		margin-left:3%;
		justify-content:flex-start;
		text-align:left;
	}

	.about-seventh-h1{
		font-size:8vw;
		text-align:center;
	}
	.intro-about-ill {
		display:none;
	}
	.intro-about-dots {
		display:none;
	}
}
.involved-color {
		background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#53B7D5;
}

.involved {
	z-index:-1;
	background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#53B7D5;
	display:grid;
	text-align:center;
	height:500px;
	justify-content:center;
	align-content:center;
	grid-gap:10px;

}




.volunteer-words{
	z-index:1;
}

.involved-second h1 {
	text-align:left;
	color:black;
	font-size:60px;
}
.volunteer-dots-container {
	left:-40%;
	position:relative;
}

.volunteer-dots {
	position:absolute;
	margin-top:-20px;
	z-index:-2;
	height:230px;
}

.involved-words {
	font-weight:600;
	font-size:18px;
	color: #ffffff;
	padding:100px;

}

.involved-words > h1 {
	font-size:60px;
}

.involved-first-second{
	background-color:#53B7D5;
	margin-top:-100px;
	height:100%;
}

.involved-second{
		padding-top:10% 0%;
	z-index:1000;
		background:#F6CA45;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:center;
}


.volunteer-sub{
		display:grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas: 
      "text pic";
	align-content:center;
	justify-content:center;
		width: var(--main-width);
		text-align:left;
}

.volunteer-container {
	z-index:100;
	color:black;
	grid-area:text;
}

.black-arrow{
	width:30px;
}

.volunteer-container > p {

}

.volunteer-form-row {
	display:grid;
	grid-template-columns:25% 1fr;
	justify-content:center;
	align-content:center;
	margin: 30px 0px;
}

.volunteer-form-row a {
	color:black;
	font-weight:700;
}

.involved-second-pic {
	margin:10%;
	grid-area:pic;
}

.involved-mail, .involved-app{
	padding:0px 25%;
}

.involved-second-mobile-image{
	display:none;

}


.involved-fourth{
	display:grid;
	margin:80px 0px;
	justify-content:center;
	align-content:center;
}

.involved-fourth-sub{

	width: var(--main-width);
}

.involved-fourth h1 {
	color:black;
}

.involved-fourth-text {
	width:40%;
}

.charity-items-row {
	display:grid;
	grid-template-columns:1fr 1fr 1fr;
	grid-gap: 60px;
}

.charity-item {
	display:grid;
	justify-content:center;
	align-items:center;
}
.charity-item-pic{
	width:100%;

}

.charity-item-tag{
	background: #FFFFFF;
/* Gray 5 */
font-weight: 600;
height:40px;
color:#8D8D8D;
border: 2px solid #E0E0E0;
box-sizing: border-box;
flex: none;
order: 0;
align-self: center;
flex-grow: 0;
margin: 10px 0px;
text-align:center;
padding:0px 10px;
  border-radius: 5px;
  	display:grid;
	justify-content:center;
	align-items:center;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}



.charity-item-tag-row{
	display:grid;
	grid-template-columns: 70% 1fr;
	justify-content:center;
	align-items:center;
}

.charity-item-title{
	margin-top:10px;
	height:80px;
}


.charity-item-container-mobile {
		position: absolute;
	left:40%;
 /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    margin-left:10%;
    margin-right:10%;
    padding:0% 5%;
    padding-bottom:0%;
	width: var(--main-width);
    height:750px;
    border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display: grid;
	grid-template-columns: 40% 1fr;
	grid-gap:5%;
	align-content:center;
	justify-content:center;
	background-color:white;
}

.charity-item-container-image{
	text-align:center;


}

.small-image-row {

	margin-top:10px;
	margin-left:30%;
	margin-right:30%;
	display:grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap:5%;

}

.charity-item-main-image {
	width:300px;
}

.charity-item-desc{
	margin-top:30px;
	height:360px;
	overflow-y: scroll;
}

.charity-item-text{
	height:600px;
}

.charity-interested-button{
	width:50px;
}

.charity-item-contact-first-row {
	display:grid;
	grid-template-columns: 1fr 1fr;
	grid-gap:5%;
}

.charity-item-contact-container {
padding:3%;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 10px;
}

.charity-item-contact-container input {
	margin-top:10px;
	width:100%;
	padding:1%;
	height:40px;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 5px;
}

.charity-item-contact-container p {
	margin-top:30px;
}

.btn-charity {

	background: #53B7D5;
border-radius: 3px;
padding:10px 40px;
color:white;
font-family: Noto Sans;
font-style: normal;
font-weight: bold;
font-size: 14px;
}

.btn-charity2 {

	background: #53B7D5;
border-radius: 3px;
padding:5px 50px;
color:white;
font-family: Noto Sans;
font-style: normal;
font-weight: bold;
font-size: 14px;
}
.charity-button-row {
	margin-top:5px;
	margin-bottom:15px;
	display:grid;
	justify-content:center;
	align-items:center;

}

.close-item-container{
	display:grid;
	justify-content:right;
}

.close-item {
	width:15px;
	text-align:right;
}

.email-row {
	margin-top:15px;
}

.detail-row{
	margin-top:15px;
}

.back-text{
	font-family: Nunito;
	font-style: normal;
	font-weight: 900;
	font-size: 14px;
	line-height: 151%;
	/* or 27px */

	letter-spacing: 0.1em;
	text-transform: uppercase;

	/* Middle grey */

	color: #C4C4C4;
}

.charity-back-arrow{
	width:8px;
	margin-right:15px;
}

.thank-you-text{
	margin-top:150px;
	font-weight: bold;
	font-size: 18px;
	text-align:center;
}


@media (max-width: 999px) { 
.charity-item-title{
	margin-top:0px;
	height:100%;
}

.small-image-row {
margin-bottom:3%;
}
	.involved {
		justify-content:inherit;
		height:100%;
		padding-top:30%;
		padding-bottom:30%;
	}


	.involved-words {
		padding:1%;
		width:100%;
	}
	.involved-words > h1 {
		font-size:8vw;
	}

	.involved-words p {
		font-size:3vw;
	}

	.involved-second-image{
		display:none;
	}
	.involved-second-mobile-image{
		display:block;
	}	

	.involved-second h1 {
		font-size:8vw;
	}

	.volunteer-container p {
		font-size:3vw;
	}

	.involved-second-pic{
		margin:0px;
		margin-bottom:5%;
		margin-top:5%;
	}
	.volunteer-sub{
		grid-template-columns: 1fr;
	}

	.involved-second{
		display:block;
		grid-template-areas: 
	      "pic"
	      "text"

	}

	.volunteer-sub{
		display:grid;
		grid-template-columns:1fr;
		grid-template-areas: 
	      "pic"
	      "text"
  }

  .involved-second{
  	padding-top:10%;
  	padding-left:3%;
  	padding-right:3%;
  	padding-bottom:10%;

  }

  .volunteer-form-row{
  	width:60%;
  }

  .involved-app{
  	padding:0px;
  	padding-right:40%;
  }

  .involved-mail{
  	padding:0px;
  	padding-right:38%;

  }

  .mail-row {
  	grid-template-columns: 15% 1fr;
  	width: 100%;
  }

  .involved-fourth {
  	padding:0px 3%;
  }

  .involved-fourth-text {
  	padding-bottom:60px;
  }

  .involved-fourth-title h1 {
  	font-size:8vw;
  }


  .involved-fourth-text{
  	width:100%;
  	font-size:4.5vw;
  }

  .charity-items-row {
  	grid-template-columns:1fr;
  }


.charity-item-container-mobile{
	display:block !important;
	align-content:flex-start;
  	position:absolute;
  	grid-template-columns:1fr;
  	margin-top:10%;
  	padding-top:40px;
  	height:1300px;
  	left:41%;
}
  .charity-item-desc{
  	height:200px;
  }

  .charity-item-text h4{
  	font-size:4vw;

  }

  .close-item {
  	width:25px;
  	height:25px;
  }

  .close-item-container{
  	padding-bottom:5%;
  }

  .charity-item-text div{
  	font-size:3.5vw;
  }

  .charity-item-contact-container div {
  	font-size:3vw;
  }

  .charity-item-contact-container input {
  	height:4vh;
  }

  .back-text {
  	font-size:3.5vw;
  }

  .charity-item-tag-row {
  	margin-top:3%;
  }

  .charity-item-tag {
  	height:6vh;
  }

  .charity-item-pic{



	}
}
@media (max-width: 450px){
	.charity-item-container-mobile{
		height:1000px;
	}
}

.archive-container{

	background-color:#ffffff;

	margin-top:50px;
	display:grid;
	align-content:center;
	justify-content:center;
	margin-bottom:100px;
}

.archive-sub{
	display:grid;
	align-content:center;
	justify-content:center;
		width: var(--main-width);

}
.archive-color {
	background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#ffffff;
	height:100px;
}

.btn-categories{

background: #FFFFFF;
/* Middle grey */

border: 0.5px solid #C4C4C4;
box-sizing: border-box;
border-radius: 5px;
display:grid;
grid-template-columns:1fr 10%; 



align-items:center;

}



.btn-categories div{

}
.archive-first-container{
	display:grid;
	grid-template-columns: 1fr 1fr;
	grid-gap:30px;
}


.archive-first-date-container{
	margin-top:30px;
	display:grid;
	grid-template-columns: 80% 1fr;
	grid-gap:30px;
}

.archive-first  h1 {
	color:black;
	font-size:60px;
}

.archive-title-row {
	display:grid;
	grid-template-columns: 1fr 15%;
}

.archive-first-text {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 160%;
	color: #102034;
}

.archive-first-date {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 151%;
	color: black;
}

.archive-tbg{
	display:grid;
	grid-template-rows:1fr;
}

.archive-tb {
	background:white;
	background-color:white !important;
}

.archive-news-image {
	margin-bottom:10px;
	width:100%;
	display:grid;
	align-items:center;
	justify-content:center;
}

.btn-group-vertical{
	margin-left:20px;
	height:1000px;
	background:white;
}

.year-dropdown{
	display:none;
}

.archive-first-container iframe {
    border: 0px;
    height: 289px;
    width: 100%;
	}

@media (max-width: 999px) { 

	.btn-categories{

	display:inline-block;

	}
	.btn-categories div {
		float:left;
		margin-right:5%;
	}


	.archive-title-row{
		text-align:center;
	}

	.archive-title-row h1{
		font-size:10vw;
	}
	.archive-first-container {
		grid-template-columns:1fr;
		display:grid;
		justify-content:center;
	}

	.archive-first-date-container{
		grid-template-columns:1fr;
	}

	.btn-group-toggle{
		display:none;
	}

	.archive-first-container iframe {
		    margin-left: auto;
    margin-right: auto;
		padding-left:5%;
		padding-right:5%;
		border:0px;
				height:300px;
		width:400px;
	}


	.archive-first-container img{
		    margin-left: auto;
    margin-right: auto;
		padding-left:5%;
		padding-right:5%;
						height:300px;
		width:400px;
	}

	.archive-news-container {
		width:500px;
		display:grid;
		justify-content: center;
	}

	.archive-news-container a {
		display:grid;
	}

	.archive-first-text-container {
				padding-left:5%;
		padding-right:5%;
		width:400px;

	}

	.archive-title-row{
		grid-template-columns:1fr;
		display:grid;
		justify-content:center;
	}

	.archive-first-text{
		font-size:4vw;
				    margin-left: auto;
    margin-right: auto;

	}

	.archive-first-date{
				font-size:4vw;
	}

	.cat-dropdown {
		display:grid;
		justify-content:center;
	}
	.year-dropdown{
	display:block;
	margin-left:auto;
	margin-right:auto;
}	
	}

@media(max-width:450px){
	.archive-first-container iframe {
	padding-left:5%;
	padding-right:5%;
	height:225px;
	width:300px;
	border:0px;
}

.archive-first-container img{
	margin-left:auto;
	margin-right:auto;
	height:225px;
	width:300px;
}

.archive-first-text-container {
		margin-left:auto;
	margin-right:auto;
	width:300px;
}
}
.back-link{
	font-family: Nunito;
font-style: normal;
font-weight: 900;
font-size: 14px;
line-height: 151%;
/* or 27px */

letter-spacing: 0.1em;
text-transform: uppercase;

/* Middle grey */

color: #C4C4C4;
}

.back-arrow{
	width:8px;
	margin-right:5px;
}

.archive-item-container{
	display:grid;
	justify-content:center;
	align-items:center;
}

.archive-item-first {

	margin-top:40px;
	width: var(--main-width);
	margin-bottom:100px;
}
.archive-item-first-container{
	display:grid;
	grid-template-columns:80% 1fr;	
	grid-gap:50px;
}

.archive-item-first-container > div > div > span {
	    height: 5px;
    display: block;
}


.archive-item-date-text {
	font-size: 13px;
line-height: 151%;
/* or 23px */


/* Dark grey */

color: #8D8D8D;
}
.archive-item-header{
	display:grid;
	grid-template-columns:70%;	
}

.archive-item-header > div > h1 {
	color:black;
	font-size:40px;
}

.side-news {
	font-size:13px;
}

.side-news a{
	color:black;
}
.side-news-date{
	color: #8D8D8D;
	margin-bottom:15px;
}


.center-cropped {
	display:grid;
	align-content:flex-start;
	justify-content: flex-start;

	position: relative;

}
.center-cropped img {

}

.archive-item-cat-title {
	margin-top:50px;
	font-size:18px;
}

.archive-item-cat {
	font-size:12px;
}

.side-news-container img{
	height:112px;
	width:150px;
}


.side-news-container iframe{
	height:112px;
	width:150px;

}
	.archive-item-image-container div {
		overflow-wrap: break-word;
		display: inline-block;
		word-break: break-word;
	}

@media (max-width: 999px) { 

	.back-link {
		font-size:4vw;
	}
	.archive-item-container{
		margin-left:3%;
		margin-right:3%;
	}
	.archive-item-header{
		grid-template-columns:1fr;
	}
		.archive-item-header >div > h1{
			font-size:8vw;
		}
	.archive-item-first-container{
		grid-template-columns:1fr;
	}
	.archive-item-image-container{
		display:grid;
		justify-content: center;
		overflow-wrap: break-word;
	}
	.archive-item-image-container div {
		overflow-wrap: break-word;
		display: inline-block;
		word-break: break-word;
		font-size:3vw;
	}
		.archive-item-image-container img{
			margin-left:auto;
			margin-right:auto;
		}

		.side-news-container{
			display:grid;
			grid-template-columns:1fr 1fr;
			grid-gap:3%;
		}

		.side-news-container img{
			height:300px;
			width:100%;
		}

		.side-news div{
			justify-content: center;
    display: grid;
    text-align:center;
		}

		.archive-news-image{
			height:100%;
			width:100%;
		}

		.side-news-container iframe{
			height:300px;
			width:100%;

		}

	.side-news:nth-last-child(1):nth-child(odd) {
	display:none;
	}
}

@media (max-width: 450px){
		.side-news-container img{
			height:150px;
			width:100%;
		}


		.side-news-container iframe{
			height:150px;
			width:100%;

		}
}
.donate{
	padding-top:200px;
	padding-bottom:100px;
	display:grid;
	justify-content: center;
	align-items: center;
	background:#F6CA45;
	z-index:-1;


}

.donate h1{
	margin-left:;
	font-family: Nunito;
font-style: normal;
font-weight: 900;
font-size: 64px;
line-height: 113.1%;
/* or 72px */


color: #072142;
}

.donate-sub{
	display:grid;
		grid-template-columns:35% 1fr;
	width: var(--main-width);
}

@media (max-width: 999px) { 
	.donate h1 {
		font-size:8vw;
	}
	.donate-text{
		text-align:center;
		display:grid;
		justify-content:center;
	}
	.donate-sub{
		grid-template-columns:1fr;
	}
	.cat-dropdown{
		margin-bottom:2%;
	}

}

.stupid-scroll-container{
	margin-left:48%;
	margin-right:48%;
	position:relative;
	display:grid;
	align-items:center;
	justify-content:center;
}
.stupid-scroll{
	z-index:10000;
	position:absolute;
	margin-top:100px;
	color:white;
	display:grid;
	align-items:center;
	justify-content:center;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
}
.stupid-scroll-line{
		z-index:10000;
	position:absolute;
	margin-left:25px;
		margin-top:200px;
	height:50px;
	width:0px;
	border-right:3px solid white;
}
.sma-wave {
	position:relative;
}

.sma-wave-image{
	position:absolute;
	margin-top:100px;
	left:70%;
	width:600px;

}

.black-arrow {
	width:10px;
}
.sma-dots {
	position:relative;
	z-index:0;
}

.sma-dots-image{

	position:absolute;
	margin-top:-40px;
	left:-2%;
	width:300px;

}


.sma-color {
		background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#53B7D5;
		display:grid;
	align-content:center;
	justify-content:center;
}

.sma {
	background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#53B7D5;
	display:grid;
	height:500px;
	width:100%;
	text-align:center;

}

.sma-first-sub{
		width: var(--main-width);
		display:grid;
		align-items:center;
}

.item-image {
	margin-right:10px;
	width:30px;
}

.sma-words {

	text-align:left;
	font-weight:bold;
	font-size:20px;
	padding:100px 0px;
	color: #ffffff;
	width:100%;
}

.sma-words > h1 {
	padding-top:5%;
	font-size:80px;
}

.sma-first-second{
	background-color:#53B7D5;

	height:100%;
}


.sma-first-links {
	margin-top:50px;
	display:grid;
	grid-gap:30px;
	grid-template-columns:1fr 1fr;
}
.sma-second{
		background:#F6CA45;
	display:grid;
	grid-template-columns: 1fr;
	align-content:center;
	justify-content:center;
}



.sma-mail, .sma-app{
	padding:0px 25%;
}

.sma-second-container {
	margin-top:60px;
	display:grid;
	grid-template-columns: 20% 50% 20%;
	grid-gap:5%;
	grid-template-areas: 
      "leftLinks items references";
}

.sma-third-container{
	margin-bottom:10px;
	display:grid;
	grid-template-columns: 20% 70%;
	grid-gap:5%;
}

.sma-second-container span {
	margin-bottom:1px;
	display:block;
}

.sma-references{
	font-size:14px;
	margin-right:100px;
	grid-area:references;
}

.sma-left-links-container{
	grid-area:leftLinks;
}

.sma-items{
	grid-area:items;
}
.sma-title {
	display:inline;
	margin-bottom:70px;
	width:100%;
	border-bottom: 1px solid black;
	font-size:18px;
	font-weight:bold;
}

.sma-title img {
	height:10px;
	margin-top:13px;
	display:inline;
	float:right;
}




.sma-leftLinks {
		position:relative;
	z-index:1000;
	margin-left:40px;
	margin-bottom:10px;
	font-size:14px;
}

.further-row > div > div{
	display:grid;
	grid-template-columns:15% 1fr;
	grid-gap:50px;
	margin-bottom:50px;
	align-items:center;
	justify-content:center;
}

.further-row > div > div > a {
	color:black;
	font-weight:bold;
}



.table-first-row{
	background: rgba(255, 255, 255, 0.5);
}

.sma-table td, th {
	padding:10px;
}

.sma-table ul{
	margin-left:-20px;
}

.sma-table {
	margin-top:50px;
}

@media (max-width: 999px) { 

	.sma {
		height:100%;

	}
	.sma-words {
		padding: 10% 0%;
	}
	

	.sma-items p{
		font-size:3vw;
	}

	.sma-title {
		font-size:4vw;
	}

	.sma-references div {
				font-size:3vw;
	}

	.sma-references b {
		font-size:4vw;
	}
		.sma-words > h1 {
			font-size:10vw;
		    text-align:center;
		}
		.sma-first-links {
			grid-gap:4%;
			margin-left:5%;
		}
		.sma-first-links div{
			font-size:2.5vw;
		}

		.sma-color{
			padding-top:10%;
			justify-content:inherit;
			padding-left:2%;
			padding-right:2%;
		}

		.sma-dots-image{
			display:none;
		}

		.sma-second-container{
			grid-template-columns: 1fr;
			padding:5%;
			grid-template-areas: 
      		"items"
      		"references";
		}
		.sma-left-links-container{
		display:none;
	}

	.sma-table td, th{
		padding: 3px;
	}
	.sma-table {
		margin-top:20px;
		font-size:10px;
		margin-left:3%;
		margin-right:3%;
	}
	.sma-third-container{
		grid-template-columns:1fr;
	}

	.sma-wave{
		display:none;
	}

	}

