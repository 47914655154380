


.intro {
	z-index:-1;
	background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#F6CA45;
	display:grid;
	grid-template-columns: 30% 1fr;
}

.main-small{
	margin-top:25px;
	font-size: 12px;
}

.white-text {
	color:#ffffff;
	font-size:18px;
}

.super-small-words{
	font-size:10px;
}

.clip-circle {
  clip-path: circle(40% at center);
	grid-area: image;
}

.intro-img{
	z-index:-10;
	display:block;
}

.intro-mobile-img{
	display:none;
}
.intro-words{
	display:grid;
	grid-template-rows:1fr;
	grid-template-columns:1fr;
	align-self:center;
	margin-left:20%;
	margin-right:20%;
}

.title {

}

.title-words > h1 {

	font-size:48px;
}

.title-words {
	margin-top:-12px;

}
.main-logo {
	float:left;
	height: 100px;
	margin-right:40px;
}

.intro-footer {
	display: grid;
	grid-template-columns: 35% 45% 15%;
	grid-gap:10px;
}

.hkcss-image{
	margin-top:0px;
}

h1{
	font-size:54px;
	color:#ffffff;
}
img { max-width: 100%; height: auto }


.intro-second{
	background-color:#F6CA45;
	margin-top:-100px;
	height:100%;

}

.stupid-scroll-container{
	margin-left:48%;
	margin-right:48%;
	position:relative;
	display:grid;
	align-items:center;
	justify-content:center;
}
.stupid-scroll{
	z-index:10000;
	position:absolute;
	margin-top:100px;
	color:white;
	display:grid;
	align-items:center;
	justify-content:center;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
}
.stupid-scroll-line{
		z-index:10000;
	position:absolute;
	margin-left:25px;
		margin-top:200px;
	height:50px;
	width:0px;
	border-right:3px solid white;
}
.intro-second-image{
	width:100%;
}


.second {
	background:#1A3C66;
	display:grid;
	align-content:center;
	justify-content: center;
}



.second-sub{
		display:grid;
	grid-template-columns: 50% 1fr;
	align-content:center;
	justify-content:center;
	height:700px;


	grid-template-areas: 
      "image words"
      "header header";
	width: var(--main-width);
}

.second-words{
	display: grid;
	align-content:center;
	margin-right:10%;
	font-size:24px;

	grid-area:words;
}

.second-words > p {
	font-size:16px;
}

.second-words > h1 {
	font-size:44px;
}
.second-link > a{
	font-weight:bolder;
	color:#F6CA45;
}
.circle-dots-parent{
	position:relative;
}
.circle-dots{
	position:absolute;
	margin-top:-80px;
	left:-60%;
	height:200px;
}

.reg-dots-parent {
	position:relative;
}
.reg-dots{
	position:absolute;
	left:-50%;
	margin-top:-80px;
	height:350px;
}

.ocean-wave-parent {
	position:relative;
}

.ocean-wave{
	position:absolute;
	right:-60%;
	height:150px;
		margin-top:-50px;
}
.third {
	padding-top:70px;
	padding-bottom:70px;
	font-family:Nunito;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:center;
}

.third-sub{

	width: var(--main-width);
}
.third-title {
	margin-left:20%;
	margin-right:20%;
	text-align:center;
	color:#102034;
	font-size:54px;
	font-family: Nunito;
	font-weight: bolder;

}

.third-text {
	margin-left:25%;
	margin-right:25%;
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 160%;
	color: #102034;
}

.third-link {
	font-family: Nunito;
	font-style: normal;
	font-weight: 900;
	font-size: 18px;
	line-height: 151%;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #53B7D5;

}

.index-down-arrow {
	width:30px;
}

.orange-arrow {
	width:30px;
}
.index-up-arrow {
	width:30px;
}

.third-container {
	width:100%;
	display: grid;
	grid-template-columns:1fr 1fr 1fr;
	grid-gap:15px;

}

.third-content {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 160%;


	text-align: center;


	margin:15%;
	color: #102034;
}

.third-content > img {
	margin-bottom:10%;
}

.white-arrow{
	height:25px;
}

.fourth {
	padding-top:70px;
	padding-bottom:150px;
	background-color:#A1CC48;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:center;
}



.fourth-sub {
	width: var(--main-width);
}
	.fourth-content iframe {
						height:364px;
		width:485px;
		border:0px;
	}
.fourth-title{
	text-align:left;
	font-family: Nunito;
	font-style: normal;
	font-weight: 900;
	font-size: 54px;
	line-height: 75px;

	color: #072142;
}

.fourth-container{

	display:grid;
	grid-template-columns:1fr 1fr;
	grid-gap:30px;
	text-align:left;
	margin-bottom:50px;

}

.fourth-text {

	font-family: Noto Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 160%;
	color: #102034;
}

.fourth-date {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 151%;
	color: #F2F2F2;
}

.fourth-link-container {
	display:grid;
	grid-template-columns:1fr;
}

.fourth-link {
	text-align:center;
	font-family: Nunito;
	font-style: normal;
	font-weight: 900;
	font-size: 18px;
	line-height: 151%;
	/* or 27px */

	letter-spacing: 0.1em;
	text-transform: uppercase;

	color: #FFFFFF;
}

.fourth-fifth{
	background-color:#A1CC48;
	margin-top:-100px;
	height:100%;

}

.fourth-image {
	margin-bottom:5px;
	width:100%;
	display:grid;
	align-items:center;
	justify-content:center;
}


.fourth-fifth-image{
	width:100%;
}

.fifth {
	padding: 80px 100px 80px 100px;
	background-image:url('/fifth-bg.png');
	    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:center;
}





.fifth-sub{
	width: var(--main-width);
}
.fifth-title {
	text-align:left;
	font-family: Nunito;
	font-size: 54px;
	font-weight:900;
	color: #ffffff;
}

.fifth-container{
	display:grid;
	grid-template-columns:1fr 1fr 1fr;
	grid-gap:20px;
}


.fifth-content {
	border-radius: 10px;
	text-align:center;
	background-color:#ffffff;
}

.fifth-text{
	margin-top:30px;
	margin-bottom:30px;
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 151%;
	/* or 23px */

	text-align: center;

	/* Dark grey */

	color: #8D8D8D;
}

.fifth-image {
	margin-left:5%;
	width:90%;
	margin-right:5%;
	display:grid;
	align-items:center;
	justify-content:center;
}

.fifth-circle-dots{
	position:absolute;
	left:105%;
	margin-top:-20px;
	height:250px;
}

.fifth-image-container{
	height:120px;
}

.sixth {
	padding:100px 0px;
	background-color:#ffffff;
	display:grid;
	align-content:center;
	justify-content:center;
	text-align:center;
}

.sixth-sub{
	width: var(--main-width);
}

.sixth-title-container {
	display:grid;
	grid-template-columns:1fr;
}
.sixth-title {
	text-align:center;
	font-family: Nunito;
	font-style: normal;
	font-weight: 900;
	font-size: 54px;
	line-height: 75px;
	text-align: center;

	color: #072142;
}

.sixth-container {
	margin-left:5%;
	margin-right:5%;
	text-align:left;
	display:grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap:20px;
}

.sixth-text {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 24px;
	/* or 160% */


	color: #102034;
}

.seventh {
	display: grid;
	justify-content:center;
	padding-top:100px;
	padding-bottom:80px;
	background-color:#102034;
}

.seventh-title-container{
	display:grid;
	grid-template-columns:1fr;
}

.seventh-title {
	font-family: Nunito;
	font-style: normal;
	font-weight: 900;
	font-size: 55px;
	line-height: 75px;
	text-align: center;

	/* White */

	color: #FFFFFF;
}

.second-fluff {
	grid-area:header;
}

@media (max-width: 999px) { 
  .intro {
    	grid-template-columns: 40% 1fr;
  }

  .intro-second{
  	margin-top:-10%;
  }

  	.intro-img{
	z-index:-10;
	display:none;
}

  .intro-words{
  	padding-top:15%;
  	margin-right:0%;
  	margin-left:0%;
  }
.intro-mobile-img{
	z-index:-10;
	display:block;
}

.intro-footer {
	display: grid;
	grid-template-columns: 1fr;
	margin-left:30px;
	color:#245798;
}

.hkcss-image{
	display:none;
}
  .middle{
  	margin-top:-5%;
  	margin-left:-8%;
  }
  .white-text{
  	font-size:3vw;
  }
  .main-small{
	margin-top:2%;
	font-size: 2vw;
	}

	.title-words > h1 {

	font-size:6vw;
	}

	.main-logo{
		display: none;
	}

	.reg-dots{
		position:absolute;
		left:-20%;
		margin-top:-10px;
		height:270px;
	}

	.circle-dots{
		position:absolute;
		margin-top:-130%;
		left:10%;
		height:120px;
	}

	.ocean-wave {
		margin-top:-150px;
		opacity: 0.2;
	}

	.stupid-scroll{
		margin-top:0px;

	}
	.stupid-scroll-line{

		margin-top:100px;

	}

	.second-sub{
		display:grid;
		grid-template-columns: 70% 1fr;
		align-content:center;
		justify-content:center;
		height:700px;
		width: var(--main-width);

		grid-template-areas: 
	      "image header"
	      "words words";
	}

	.clip-circle {
	  clip-path: circle(40% at center);


	}


	.second-image {

	}
	.second-words {
		margin-top:10%;
		margin-left:30%;
		margin-right:10%;
		width:60%;
	}

	.second-words h1 {
		font-size:8vw;
	}

	.second-words > p {
		font-size:12px;
	}

	.third-title {
				font-size:8vw;
		margin-left:10%;
		margin-right:10%;
	}

	.third-text {
		margin-left:10%;
		margin-right:10%;
		font-size:14px;
	}

	.third-link {
		font-size:14px;

	}

	.third-container {
			display: grid;
	grid-template-columns: repeat( 4, 1fr );
	}

	.third-content {
		font-size:3vw;
			grid-column: span 2;
	}

	.third-content:nth-last-child(1):nth-child(odd) {
	grid-column: 2 / span 2;
	}

	.fourth-container{

	display:grid;
	grid-template-columns:1fr;
	grid-gap:30px;
	text-align:center;
	margin-bottom:50px;

	}

	.fourth-content{
				display:grid;
		justify-content:center;
	}



	.fourth-content iframe {
		padding-left:5%;
		padding-right:5%;
		height:300px;
		width:400px;
		border:0px;
	}

	.fourth-image{
		margin-left:auto;
		margin-right:auto;
		height:300px;
		width:400px;
	}

	.fourth-image, .fourth-text{
		font-size:4vw;
		padding-left:5%;
		padding-right:5%;
	}

	.fourth-title{
		text-align:center;
		font-size:8vw;
		
	}

	.fifth-title {
		text-align:center;
		font-size:8vw;
	}

	.fifth-container {
		grid-template-columns: 1fr 1fr;
		grid-gap:15px;
	}

	.fifth-text{
		margin-bottom:10px;
		margin-top:10px;
		font-size:13px;
	}

	.fifth-image-container{
		height:50%;
	}
	.fifth {
		padding:40px 15px;
	 	padding-bottom:30px;
	}

	.sixth {
		padding:40px 0px;
	}

	.sixth-container {
		grid-template-columns: 1fr 1fr;
		grid-gap:5px;
	}

	.sixth-title {
		font-size:9vw;
	}

	.sixth-text {
		font-size: 3vw;
		margin-left:4%;
	}
	.seventh {
		padding-top:40px;
	}
	.seventh-title {
		padding:5% 0%;
		font-size:8vw;
	}

	.stupid-scroll-container{
		display:none;
	}


}

@media(max-width:450px){
		.fourth-content iframe {
		padding-left:5%;
		padding-right:5%;
		height:225px;
		width:300px;
		border:0px;
	}

	.fourth-image{
		margin-left:auto;
		margin-right:auto;
		height:225px;
		width:300px;
	}
}