
.home-link {
	color:blue;
}

.org-container {
	margin:60px;
}

.org-ta {
	height:300px;
	width:100%;
}

.org-staff-row{
	display:grid;
	grid-template-columns:30% 30% 30%;
	  grid-gap:20px;
	  margin-bottom:30px;

}
.org-subcontainer {
	margin:30px 0px;
}

.org-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	padding:30px;
}


.org-language-box {
	width:100%;

}

.org-name-ta {
	width:100%;
	height:30px;
}

.org-names-ta {
	width:100%;
	height:100px;
}


.org-title-ta {
	width:100%;
	height:50px;
}

.org-row {
  display:grid;
  grid-template-columns:30% 30% 30%;
  grid-gap:20px;
  margin-bottom:30px;
  padding-bottom:30px;
  border-bottom: 1px grey solid
}