@import url('//fonts.googleapis.com/css2?family=Avenir');
@import url('//fonts.googleapis.com/css2?family=Noto+Sans');

:root {
  --main-width: 1000px;
}
@media (max-width: 999px) { 
  :root {
    --main-width: 100%;
  }
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(./fonts/Nunito-Regular.ttf) format('truetype');
}

h1{
  font-family:'Nunito';
  font-weight:900 !important;
  font-style: normal;
}
span {
  display:block;
  margin-bottom:20px;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Nunito", "Noto Sans"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x :hidden;
}

html,body
{
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p + p {
  margin-top: 0;
}

.pointer {
  cursor:pointer;
}

.relative {
  position: relative;
}

button {
  float:right;
}

@media (max-width: 999px) { 
  h1{
    font-size:8vw;
  }

  div{
    font-size:4vw;
  }
  }
