
.home-link {
	color:blue;
}

.news-container {
	margin:60px;

}

.news-ta {
	height:300px;
	width:100%;
}

.news-subcontainer {
	display:grid;
	grid-template-columns: 70% 1fr;
	grid-gap:50px;
}

.news-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	padding:30px;
	margin:20px 0px;
}

.an-links{
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	grid-template-columns:1fr 1fr 1fr 1fr;
	text-align:right;
	padding-right:10px;
}

.an-dropdown{
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	justify-content: center;
	align-content:center;
	padding:30px;

}

.an-links > a {
	color:#4F4F4F;
	margin:3px;

}

.an-links > a:hover {
	color:#4F4F4F;
}
.cover-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:500px;
	display:grid;
	padding:30px;
}


.language-box {
	width:100%;

}

.header-ta {
	width:100%;
}

.content-ta {
	width:100%;
	height:400px;
}

.image-button {
	height:50px;
}

.image-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:200px;
	display:grid;
	padding:30px;
}

.an-checkbox{
	display:grid;
	grid-gap:5px;
	margin-top:30px;
}