.about-color {
	position:relative;
	z-index:-100;
		background:linear-gradient(180deg, #424242 -100.3%, rgba(196, 196, 196, 0) 70%);
	background-color:#53B7D5;
}

.about {

	display:grid;
	height:700px;

	align-content:center;
	justify-content:center;
}


.about-sub{
	display:grid;
	grid-template-columns: 60% 1fr;
	grid-gap:0px;
	width: var(--main-width);
}


.intro-about-ill{
	z-index:-1;
	position:absolute;
	left:70%;
	margin-top:10%;
	height:580px;

}

.intro-about-dots{
	z-index:-1;
	position:absolute;
	left:-1%;
	margin-top:22%;
	height:250px;

}

.intro-about-wave{
		z-index:1200;
	position:absolute;
	left:80%;
	height:200px;
}


.about-pic{
	position:relative;
	z-index:1000;
}

.intro-second-image{
	position:relative;
	z-index:1000;
}
.about-intro-second{
	position:relative;
	z-index:1000;
	margin-top:-100px;
	height:100%;
}

.about-words {
	padding:100px;
	width:100%;
}

.about-words > h1 {
	font-size:60px;
}
.about-words > p {
	width:90%;
	color:#ffffff;
}

.about-second {
	background:#1A3C66;
	display:grid;
	align-content:center;
	justify-content: center;
	padding-bottom:5%;
}


.about-clip-circle {
  clip-path: circle(40% at center);
	grid-area: image;
}

.about-second-fluff {
	grid-area:header;
}

.about-second-sub{
		display:grid;
	grid-template-columns: 50% 1fr;
	align-content:center;
	justify-content:center;
	height:700px;
	width: var(--main-width);

	grid-template-areas: 
      "image words"
      "header header";
}



.about-second-words {
	display: grid;
	align-content:center;
	margin-right:10%;
	color:white;
	grid-area:words;
}

.about-second-words > h1 {
	margin-bottom:30px;
}

.about-second-words > ul {
	margin-left:-20px;
}

.about-third {
	padding-top:70px;
	padding-bottom:70px;
	display:grid;
	align-content:center;
	justify-content: center;

	text-align:center;
}

.about-third-title {
	display:grid;
	grid-template-columns:50% 1fr;
	text-align:left;
	margin-bottom:30px;

}

.about-third-sub {
	width: var(--main-width);
}

.about-third-h1 {
	color:black;
	font-size:74px;
	font-weight:900;
		font-family:Nunito;
}

.about-third-row {
	display:grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap:15px;
	text-align:left;
}

.about-third-item {
	display: grid;
	grid-column: span 2;
	grid-template-columns: 20% 1fr;
	grid-gap:15px;
}


.about-third-item:nth-last-child(1):nth-child(odd) {
	grid-column: 2 / span 2;
}


.about-fourth {
	padding-top:70px;
	padding-bottom:70px;
	background: #F6CA45;
	text-align:left;
	margin-bottom:30px;
display:grid;
	align-content:center;
	justify-content: center;
}

.about-fourth-sub {
	width: var(--main-width);
}


.about-fourth-title {
	display:grid;
	grid-template-columns:50% 1fr;
	text-align:left;
	margin-bottom:30px;

}

.about-fourth-forms {
	display:grid;
	grid-template-columns:40% 40%;
	text-align:left;
	grid-gap:30px;
	margin-bottom:10px;

}

.about-fourth-h1 {
	font-family:'Nunito';
	color:black;
	font-size:48px;
	font-style: normal;
	font-weight: 900;
	line-height: 75px;
}

.about-fifth {
	height:700px;
	padding:100px 0px;
display:grid;
	align-content:center;
	justify-content: center;
}

.about-fifth-sub {
	width: var(--main-width);
}

.about-fifth-h1 {
	font-family:'Nunito';
	color:black;
	font-size:74px;
	font-style: normal;
	font-weight: 900;
	font-size: 55px;
	line-height: 75px;
}

.about-sixth {
	padding:100px 0px;
	background-color:#1A3C66;
	display:grid;
	align-content:center;
	justify-content: center;
}

.about-sixth-sub {
	width: var(--main-width);
}


.about-sixth-first-row {
	margin-top:50px;
	display:grid;
	grid-template-columns:1fr 1fr;
	justify-content:top;
	align-content:top;
	text-align:center;
}

.about-sixth-row {
	margin-top:50px;
	display:grid;
	grid-template-columns:1fr 1fr 1fr;
	justify-content:center;
	text-align:center;
}

.about-sixth-last-row {
	display:grid;
	grid-template-columns:1fr 1fr 1fr;
	justify-content:center;
	text-align:center;
}
.about-sixth-item > h2 {
	text-transform:uppercase;
	font-size:16px;
	color:#F2F2F2;
	border-bottom:1px solid #F2F2F2;
	padding:10px;
	margin-bottom:10px;
	opacity: 0.8;
}

.about-fsma-staff {
	margin-top:50px;
}
.about-fsma-staff > h2 {
	margin-left:auto;
	margin-right:auto;
	text-transform:uppercase;
	font-size:16px;
	color:#F2F2F2;
	border-bottom:1px solid #F2F2F2;
	padding:10px;
	opacity: 0.8;
	text-align:center;
	width:300px;
}

.about-sixth-item {
	  margin-left: auto;
	  margin-right:auto;

		width:300px;
}
.about-sixth-first-name {
	font-size: 20px;
	color: #F9F9F9;
}

.about-sixth-first-title {
	font-weight:300;
	font-size:16px;
    color: #F9F9F9;
    opacity: 0.8;
    margin-left:10%;
    margin-right:10%;
}
.about-sixth-title {
	display:grid;
	grid-template-columns: 1fr;
	text-align:center;
	justify-content: center;
}

.about-sixth-item {
	color:white;
}

.about-seventh {
	height:1000px;
	padding:70px 0px;
	display:grid;
	align-content:center;
	justify-content: center;
}

.about-seventh-sub {
	width: var(--main-width);
}


.about-seventh-h1 {
	font-family:'Nunito';
	color:black;
	font-size:74px;
	font-style: normal;
	font-weight: 900;
	font-size: 55px;
	line-height: 75px;
}


.about-seventh-title {
	display:grid;
	grid-template-columns: 1fr;
	text-align:center;
	justify-content: center;
}

.about-seventh-image {
	padding:100px;
}
.carousel-control-next-icon{
	width:50px !important;
	height:50px !important;
	background-image: url('/rightarrow.png') !important;
}

.carousel-control-prev-icon{
	width:50px !important;
	height:50px !important;
	background-image: url('/leftarrow.png') !important;
}

.carousel-indicators {
	top:400px;
	height:20px;

}
.carousel-indicators li {
	height:0px;
	border-top:0px;
	background-color:grey !important;
}

.appreciation {
	width:74%;
	margin-left:13%;
	margin-right:13%;
}

.appreciation-card {
	display:grid;
	grid-template-columns:20% 1fr;
	grid-gap:20px;
	padding:30px;
	align-content:center;

}

.card {
	border:0px !important;
}

.card-color{
	background:#F6CA45;
	border-radius: 25px;
}

.card-color-blue{
	background:#53B7D5;
	border-radius: 25px;
}


.appreciation-title {
	text-align:center;
}
.appreciation-words {
	display:grid;
	align-content:center;
}

.form-container > div{
	display:grid;
	grid-template-columns:10% 1fr;
}

.absolute {
	position:absolute;
}


.form-link {
	margin-left:10px;
	display:grid;
}

.arrow {
	height:25px;
}
.form-link > a {
	color:black;
}

.form-link > a:hover {
	color:black;
}

@media (max-width: 999px) { 

	.about {
		height:100%;
		padding-top:40%;
		padding-bottom:40%;
	}
	.about-sub {
		grid-template-columns: 1fr 1fr;

	}

	.about-words {
		margin-top:10%;
		padding-left: 10%;
		padding-right:0%;
		padding-top:0%;
		padding-bottom:0%;
		grid-gap:5px;
	}

	.about-words > h1{
		font-size:7vw;
	}

	.about-words > p {
		width:100%;
		font-size: 3vw;
	}

	.about-pic {
		margin-top:0%;
		padding:10px;
	}

	.about-intro-second {
		margin-top:-30%;
	}
	.about-second-words{
		padding-left:20%;
		margin-right:5%;
		font-size:3vw;
	}

		.about-second-words li{
			font-size:3vw;
		}

	.about-second-words h1 {
		font-size:8vw;
	}
	.about-second-sub{
		height:100%;
		padding-top:0%;
		padding-bottom:10%;

		grid-template-areas: 
	      "image header"
	      "words words";
	}

	.about-third {
		margin-left:5%;
		margin-right:5%;
				font-size:3vw;
	}

	.about-third-title{
		grid-template-columns:1fr;

	}
	.about-third-title p{

		font-size:3.5vw;
	}
	.about-third-h1{
		font-size:8vw;
		font-weight:900;
	}

	.about-third-row{
		grid-template-columns: repeat(2,1fr);

	}

	.about-third-item-text{
		font-size:3vw;
	}
	.about-third-item:nth-last-child(1):nth-child(odd) {
		grid-column: span 2;
	}

	.about-fourth{
		padding-left:5%;
		padding-right:5%;

	}

	.about-fourth-h1 {
		font-size:8vw;
	}

	.about-fourth-title{
		grid-template-columns:1fr;
	}

	.about-fourth-title p{
		font-size:3.5vw;
	}

	.about-fourth-forms{
		grid-template-columns:1fr;
	}

	.about-fifth {
		padding:0% 5%;
		height:100%;
		margin-bottom:10%;
	}

	.about-fifth-h1{
		text-align:center;
		font-size:8vw;

	}

	.appreciation-card {
		grid-template-columns:1fr;
	}

	.appreciation-title img {
		max-width:50%;
	}

	.card-body {
		padding:0px;
	}

	.appreciation {
		width:100%;
		margin-left:0%;
		margin-right:0%;
	}

	.about-sixth h1{
		font-size:8vw;
	}

	.about-sixth {
		padding-left:5%;
		padding-right:5%;
	}

	.about-sixth-first-row{
		grid-template-columns:1fr;
	}
	.about-sixth-row{
		margin-top:0px;
		grid-template-columns: repeat(4,1fr);
	}

	.about-sixth-item{
		grid-column: span 2;
		width:80%;
		margin-bottom:10%;
	}
	.about-sixth-item p {
		font-size:3vw;
	}

	.about-sixth h2 {
		padding:5px;
		font-size:4vw;
		margin-left:auto;
		margin-right:auto;
		width:80%;
		margin-bottom:25px;
	}

	.about-sixth-item:nth-last-child(1):nth-child(odd) {
		grid-column: 2 / span 2;
	}

	.about-sixth-first-name {
		font-size:14px;
	}

	.about-members {
		width:100%;
	}

	.about-members > h2 {
		font-size:4vw;
	}

	.trustee h2 {
		height:28%;
	}

	.about-sixth-last-row {
		grid-template-columns:repeat(4,1fr);;
	}

	.about-seventh{
		height:100%;
	}

	.about-seventh-image{
		padding:1%;
	}

	.about-seventh-title{
		margin-left:3%;
		justify-content:flex-start;
		text-align:left;
	}

	.about-seventh-h1{
		font-size:8vw;
		text-align:center;
	}
	.intro-about-ill {
		display:none;
	}
	.intro-about-dots {
		display:none;
	}
}