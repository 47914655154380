
.home-link {
	color:blue;
}

.patient-container {
	margin:60px;
}

.patient-ta {
	height:30px;
	width:50%;
	margin-top:20px;

}

.patient-subcontainer {
	display:grid;
	grid-template-columns: 70% 1fr;
	grid-gap:50px;
}

.patient-container button {
	float:left;
}

.patient-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	display:grid;
	padding:30px;
}

.patient-checkbox {
	margin-left:20px;
}

.new-form-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	padding:20px;

}

.form-row {
	margin:30px;
	display:grid;
	grid-template-columns:1fr;
}

.replace-btn {
	width:50%;
}

.edit-font {
	color:green;
}

.edit-font:hover {
	color:green;
}

.tag {
	margin-left:10px;
	display:inline-grid;
		border: 1px solid #C4C4C4;
	box-sizing: border-box;
	padding:5px 30px;
}

.edit-btn {
	margin-top:-500px;
	margin-left: 20px;
}

.edit-form {
	margin-bottom:20px;
}

.replace-btn {
	width:200px;
}

.inline {
	display: inline
}