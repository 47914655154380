.donate{
	padding-top:200px;
	padding-bottom:100px;
	display:grid;
	justify-content: center;
	align-items: center;
	background:#F6CA45;
	z-index:-1;


}

.donate h1{
	margin-left:;
	font-family: Nunito;
font-style: normal;
font-weight: 900;
font-size: 64px;
line-height: 113.1%;
/* or 72px */


color: #072142;
}

.donate-sub{
	display:grid;
		grid-template-columns:35% 1fr;
	width: var(--main-width);
}

@media (max-width: 999px) { 
	.donate h1 {
		font-size:8vw;
	}
	.donate-text{
		text-align:center;
		display:grid;
		justify-content:center;
	}
	.donate-sub{
		grid-template-columns:1fr;
	}
	.cat-dropdown{
		margin-bottom:2%;
	}

}