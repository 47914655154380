
.home-link {
	color:blue;
}

.na-container {
	margin:60px;
}

.na-ta {
	height:300px;
	width:100%;
}

.na-subcontainer {
	display:grid;
	grid-template-columns: 70% 1fr;
	grid-gap:50px;
	margin:30px 0px;
}

.na-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:700px;
	display:grid;
	padding:30px;
}

.na-cover-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:500px;
	display:grid;
	padding:30px;
}


.na-language-box {
	width:100%;

}

.na-author-ta {
	width:100%;
}

.na-details-ta {
	width:100%;
}

.na-note-ta {
	width:100%;
	height:370px;
}

.na-image-button {
	height:50px;
}

.na-image-box {
	border: 1px solid #C4C4C4;
	box-sizing: border-box;
	height:250px;
	display:grid;
	padding:30px;
}
