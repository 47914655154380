.back-link{
	font-family: Nunito;
font-style: normal;
font-weight: 900;
font-size: 14px;
line-height: 151%;
/* or 27px */

letter-spacing: 0.1em;
text-transform: uppercase;

/* Middle grey */

color: #C4C4C4;
}

.back-arrow{
	width:8px;
	margin-right:5px;
}

.archive-item-container{
	display:grid;
	justify-content:center;
	align-items:center;
}

.archive-item-first {

	margin-top:40px;
	width: var(--main-width);
	margin-bottom:100px;
}
.archive-item-first-container{
	display:grid;
	grid-template-columns:80% 1fr;	
	grid-gap:50px;
}

.archive-item-first-container > div > div > span {
	    height: 5px;
    display: block;
}


.archive-item-date-text {
	font-size: 13px;
line-height: 151%;
/* or 23px */


/* Dark grey */

color: #8D8D8D;
}
.archive-item-header{
	display:grid;
	grid-template-columns:70%;	
}

.archive-item-header > div > h1 {
	color:black;
	font-size:40px;
}

.side-news {
	font-size:13px;
}

.side-news a{
	color:black;
}
.side-news-date{
	color: #8D8D8D;
	margin-bottom:15px;
}


.center-cropped {
	display:grid;
	align-content:flex-start;
	justify-content: flex-start;

	position: relative;

}
.center-cropped img {

}

.archive-item-cat-title {
	margin-top:50px;
	font-size:18px;
}

.archive-item-cat {
	font-size:12px;
}

.side-news-container img{
	height:112px;
	width:150px;
}


.side-news-container iframe{
	height:112px;
	width:150px;

}
	.archive-item-image-container div {
		overflow-wrap: break-word;
		display: inline-block;
		word-break: break-word;
	}

@media (max-width: 999px) { 

	.back-link {
		font-size:4vw;
	}
	.archive-item-container{
		margin-left:3%;
		margin-right:3%;
	}
	.archive-item-header{
		grid-template-columns:1fr;
	}
		.archive-item-header >div > h1{
			font-size:8vw;
		}
	.archive-item-first-container{
		grid-template-columns:1fr;
	}
	.archive-item-image-container{
		display:grid;
		justify-content: center;
		overflow-wrap: break-word;
	}
	.archive-item-image-container div {
		overflow-wrap: break-word;
		display: inline-block;
		word-break: break-word;
		font-size:3vw;
	}
		.archive-item-image-container img{
			margin-left:auto;
			margin-right:auto;
		}

		.side-news-container{
			display:grid;
			grid-template-columns:1fr 1fr;
			grid-gap:3%;
		}

		.side-news-container img{
			height:300px;
			width:100%;
		}

		.side-news div{
			justify-content: center;
    display: grid;
    text-align:center;
		}

		.archive-news-image{
			height:100%;
			width:100%;
		}

		.side-news-container iframe{
			height:300px;
			width:100%;

		}

	.side-news:nth-last-child(1):nth-child(odd) {
	display:none;
	}
}

@media (max-width: 450px){
		.side-news-container img{
			height:150px;
			width:100%;
		}


		.side-news-container iframe{
			height:150px;
			width:100%;

		}
}